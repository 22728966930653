<app-page>
    <div>
        <button mat-button (click)="accordion.openAll()">{{ 'expand_all' | translate }}</button>
        <button mat-button (click)="accordion.closeAll()">{{ 'collapse_all' | translate }}</button>
    </div>

    <mat-accordion multi>
        <!-- ------------------------------------------------------------------ -->
        <!-- Order Receipt -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'order_receipt' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div appFieldContainer>
                <mat-form-field appField>
                    <mat-label>{{ 'order_id' | translate }}</mat-label>
                    <input matInput name="OrderId" [formControl]="orderReceipt.orderId" required>
                    <mat-error *ngIf="orderReceipt.orderId.invalid">{{formService.getFieldErrorMessage(orderReceipt.orderId)}}</mat-error>
                </mat-form-field>
                <br>
                <app-action [text]="'submit' | translate" [disabled]="orderReceipt.orderId.invalid" (onExecute)="openOrderReceipt(orderReceipt.orderId.value)">
                </app-action>
            </div>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Read Proto -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'read_proto' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form #readProtoForm method="POST" [action]="protoRead.actionUrl" target="_blank" appFieldContainer>
                <input type="hidden" name="AuthToken" [value]="sessionService.getAuthToken()"/>
                <mat-form-field appField>
                    <mat-label>{{ 'proto_type' | translate }}</mat-label>
                    <mat-select [formControl]="protoRead.protoType" required>
                        <mat-option *ngFor="let protoType of PROTO_TYPES; index as i" [value]="PROTO_TYPES_VALUES[i]">
                            {{ protoType }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <br>
                <mat-form-field appField>
                    <mat-label>{{ 'proto_id' | translate }}</mat-label>
                    <input matInput name="ProtoId" [formControl]="protoRead.protoId" required>
                    <mat-error *ngIf="protoRead.protoId.invalid">{{formService.getFieldErrorMessage(protoRead.protoId)}}</mat-error>
                </mat-form-field>
                <br>
                <app-action [text]="'submit' | translate" [disabled]="protoRead.protoId.invalid" (onExecute)="readProtoForm.submit()">
                </app-action>
            </form>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Write Proto -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'write_proto' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form #writeProtoForm method="POST" [action]="protoWrite.actionUrl" target="_blank" appFieldContainer>
                <input type="hidden" name="AuthToken" [value]="sessionService.getAuthToken()"/>
                <mat-form-field appField>
                    <mat-label>{{ 'proto_type' | translate }}</mat-label>
                    <mat-select [formControl]="protoWrite.protoType" required>
                        <mat-option *ngFor="let protoType of PROTO_TYPES; index as i" [value]="PROTO_TYPES_VALUES[i]">
                            {{ protoType }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <br>
                <mat-form-field appField>
                    <mat-label>{{ 'text_proto' | translate }}</mat-label>
                    <textarea matInput name="textproto" [formControl]="protoWrite.textProto" required></textarea>
                    <mat-error *ngIf="protoWrite.textProto.invalid">{{formService.getFieldErrorMessage(protoWrite.textProto)}}</mat-error>
                </mat-form-field>
                <br>
                <app-action [text]="'submit' | translate" [disabled]="protoWrite.textProto.invalid" (onExecute)="writeProtoForm.submit()">
                </app-action>
            </form>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Generate QR Code -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'generate_qr_code' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form #generateQrCodeForm method="POST" [action]="EnvironmentUtil.resolveBackendUrl('/service/user/qrcode/encode')" target="_blank" appFieldContainer>
                <input type="hidden" name="Auth-Token" [value]="sessionService.getAuthToken()"/>
                <mat-form-field appField>
                    <mat-label>{{ 'text_to_encode' | translate }}</mat-label>
                    <input matInput name="data" [formControl]="generateQrCode.textToEncode" required>
                    <mat-error *ngIf="generateQrCode.textToEncode.invalid">{{formService.getFieldErrorMessage(generateQrCode.textToEncode)}}</mat-error>
                </mat-form-field>
                <br>
                <app-action [text]="'submit' | translate" [disabled]="generateQrCode.textToEncode.invalid" (onExecute)="generateQrCodeForm.submit()">
                </app-action>
            </form>
        </mat-expansion-panel>
</mat-accordion>
</app-page>
