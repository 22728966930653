import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { LocalizationService } from '../../../general/services/localization.service';
import { SessionService } from '../../services/session.service';
import { BusinessSearchComponent } from '../../components/business-search/business-search.component';
import { MatDividerModule } from '@angular/material/divider';
import { BusinessComponent } from '../../components/business/business.component';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationStatus } from '../../util/util';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    BusinessSearchComponent,
    MatDividerModule,
    BusinessComponent,
    MatTooltipModule,
    TranslateModule,
    ActionComponent
  ],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  selectedBusiness: proto.waiternow.common.IBusinessProto | undefined;

  constructor(
      public localizationService: LocalizationService,
      private sessionService: SessionService,
      private navigationService: NavigationService,
      private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }
    this.navigationService.handleRedirect(this.activatedRoute, /* onNotRedirected= */ () => {
      // Put any remaining code on ngOnInit() inside this scope. Nothing should be after
      // this.navigationService.handleRedirect() otherwise it will execute regardless
      // the page is redirected.
      // navigationService.handleRedirect() uses async code to get query parameters and
      // thus it cannot return whether the page was redirected as
      // sessionService.enforceAuthentication() does.
    });
  }

  public onBusinessSelectionChange(business: proto.waiternow.common.IBusinessProto): void {
    this.selectedBusiness = business;
  }

  public openSelectedBusinessInNewTab(): void {
    if (this.selectedBusiness) {
      this.navigationService.openInNewTab('business/' + this.selectedBusiness.id);
    }
  }
}
