import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IndeterminatePaginatorComponent } from 'src/app/general/components/indeterminate-paginator/indeterminate-paginator.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { IndeterminatePaginatorData, IndeterminatePaginatorModel } from 'src/app/general/components/indeterminate-paginator/indeterminate-paginator-model';
import { Formatter } from '../../util/formatter';
import { SessionService } from '../../services/session.service';
import { OrdersModel } from './orders-model';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { CompactTextColumn, DataTableColumn, LinkColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { Util } from 'src/app/general/util/util';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { OrderToolbarModel } from '../../model/order-toolbar-model';
import { NavigationService } from '../../services/navigation.service';
import { BackendService } from '../../services/backend.service';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import { DisputeDialogService } from '../dispute-dialog/dispute-dialog.service';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// To reset the orders just change the orders model instance
// <app-orders [ordersModel]="myOrdersModelImplementation"></app-orders>
// Example:
// <app-orders [ordersModel]="locationPaidOrdersModel"></app-orders>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    CommonModule,
    IndeterminatePaginatorComponent,
    TranslateModule,
    LoadableContentComponent,
    MatCardModule,
    DataTableComponent
  ],
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements IndeterminatePaginatorModel<proto.waiternow.common.IOrderProto>, OnInit, OnChanges {
  @Input() ordersModel!: OrdersModel;

  ordersLoadingController: LoadingController;

  orders: Array<proto.waiternow.common.IOrderProto>;
  initialPaginatorData: IndeterminatePaginatorData<proto.waiternow.common.IOrderProto>;
  paginatorModel: IndeterminatePaginatorModel<proto.waiternow.common.IOrderProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.IOrderProto>>;

  orderToolbarModel: OrderToolbarModel;

  constructor(
      public sessionService: SessionService,
      private navigationService: NavigationService,
      private backendService: BackendService,
      private dialogService: DialogService,
      private disputeDialogService: DisputeDialogService,
      private translateService: TranslateService,
      private toastService: ToastService) {
    this.ordersLoadingController = new LoadingController();

    this.orders = new Array();
    this.initialPaginatorData = { data: new Array(), continuationToken: ''};
    this.paginatorModel = this;
    this.columns = [
      new CompactTextColumn(
        /* name= */ 'id',
        /* translateId= */ 'id',
        /* valueExtractor= */  order => order.id,
        /* compactTextLength= */ 6),
      new StringColumn(
        /* name= */ 'orderNUmber',
        /* translateId= */ 'order_number',
        /* valueExtractor= */  order => "" + order.orderNumber),
      new StringColumn(
        /* name= */ 'creationTime',
        /* translateId= */ 'creation_time',
        /* valueExtractor= */  order => Formatter.formatTimestampProto(order.creationTime)),
      new StringColumn(
        /* name= */ 'pointOfServiceFriendlyName',
        /* translateId= */ 'PoS',
        /* valueExtractor= */  order => Util.safeString(order.redundantData?.pointOfServiceFriendlyName),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'status',
        /* translateId= */ 'status',
        /* valueExtractor= */  order => Formatter.formatOrderStatus(order),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'paymentStatus',
        /* translateId= */ 'payment_status',
        /* valueExtractor= */  order => Formatter.formatOrderPaymentStatus(order),
        /* hideInCompactMode= */ true),
      new LinkColumn(
        /* name= */ 'payment',
        /* translateId= */ 'stripe_payment',
        /* urlExtractor= */  order => {
          if (!order.status || !order.status.payment || !order.status.payment.id) {
            return '';
          }
          return 'https://dashboard.stripe.com/payments/' + order.status.payment.id;
        },
        /* textExtractor= */  order => {
          if (!order.status || !order.status.payment || !order.status.payment.id) {
            return '';
          }
          return order.status.payment.id;
        },
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'orderType',
        /* translateId= */ 'order_type',
        /* valueExtractor= */  order => Formatter.formatOrderType(order),
        /* hideInCompactMode= */ true),
    ];

    this.orderToolbarModel = new OrderToolbarModel(
      this.sessionService,
      this.backendService,
      this.navigationService,
      this.translateService,
      this.dialogService,
      this.disputeDialogService,
      this.toastService,
      /* compactForMobile= */ true
    );
  }

  ngOnInit(): void {
    const additionalColumns = this.ordersModel.getAdditionalColumns();
    for (let column of additionalColumns) {
      this.columns.push(column);
    }
    this.loadInitialData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('ordersModel', changes)) {
      this.ngOnInit();
    }
  }

  updatePage(page: proto.waiternow.common.IOrderProto[]): void {
    this.orders = page;
  }

  fetchData(continuationToken: string | null | undefined): Observable<IndeterminatePaginatorData<proto.waiternow.common.IOrderProto>> {
    return this.ordersModel.fetchData(continuationToken);
  }

  public loadInitialData(): void {
    this.ordersLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_orders'));
    this.ordersModel.fetchData(/* continuationToken= */ null).subscribe(
      {
        next: paginatorData => {
          this.initialPaginatorData = paginatorData;
          this.ordersLoadingController.onSuccess();
        },
        error: error => {
          this.ordersLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_orders'));
        }
      }
    );
  }
}
