import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { SessionService } from '../../services/session.service';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DeviceService } from '../../../general/services/device.service';
import { FormService } from '../../../general/services/form.service';
import { BackendService } from '../../services/backend.service';
import { ProtoUtil } from '../../util/proto-util';
import { TableLayoutComponent } from 'src/app/general/components/table-layout/table-layout.component';
import { CURRENCIES, DISTANCE_UNITS, DISTANCE_UNITS_NAMES } from '../../util/constants';
import { Formatter } from '../../util/formatter';
import { AuthenticationStatus } from '../../util/util';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { EnvironmentUtil } from '../../util/environment-util';
import { Money } from 'src/app/general/components/money-picker/money-picker.component';
import { MoneyPickerComponent } from 'src/app/general/components/money-picker/money-picker.component';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ErrorResult, SuccessResult } from "src/app/general/util/result";
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-debug-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    MatExpansionModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TableLayoutComponent,
    FieldContainerDirective,
    FieldDirective,
    MoneyPickerComponent,
    ActionComponent
  ],
  templateUrl: './debug-page.component.html',
  styleUrls: ['./debug-page.component.css', '../../../../styles.css']
})
export class DebugPageComponent implements OnInit {
  readonly EnvironmentUtil = EnvironmentUtil;
  readonly CURRENCIES: Array<string> = CURRENCIES;

  // This is set after the view is initialized
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  encryption = new (class {
    textToEncrypt: FormControl = new FormControl('', [Validators.required]);
  })();

  addressValidationFields = new (class {
    address: FormControl = new FormControl('', [Validators.required]);
    response: string = '';
  })();

  geoDistanceFields = new (class {
    fromAddress: FormControl = new FormControl('', [Validators.required]);
    toAddress: FormControl = new FormControl('', [Validators.required]);
    response: string = '';
  })();

  deliveryQuoteFields = new (class {
    locationId: FormControl = new FormControl('', [Validators.required]);
    address: FormControl = new FormControl('', [Validators.required]);
    email: FormControl = new FormControl('', [Validators.required, Validators.email]);
    phoneNumber: FormControl = new FormControl('', [Validators.required]);
    deliveryTip: Money | null | undefined;
    response: string = '';
  })();

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      public sessionService: SessionService,
      private backendService: BackendService) {
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }
  }

  public validateAddress(actionObserver: ActionObserver): void {
    this.addressValidationFields.response = '';
    const address = ProtoUtil.createSimpleAddressProto(this.addressValidationFields.address.value);
    this.backendService.validateAddress(
      address,
      /* onSuccess= */ isValid => {
        this.addressValidationFields.response = 'is valid: ' + isValid;
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        if (this.sessionService.handleAuthenticationError(error)) {
          return;
        }
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_response'));
      }
    );
  }

  public calculateGeoDistance(actionObserver: ActionObserver): void {
    this.geoDistanceFields.response = '';
    const fromAddress = ProtoUtil.createSimpleAddressProto(this.geoDistanceFields.fromAddress.value);
    const toAddress = ProtoUtil.createSimpleAddressProto(this.geoDistanceFields.toAddress.value);
    this.backendService.calculateGeoDistance(
      fromAddress,
      toAddress,
      /* onSuccess= */ geoDistance => {
        this.geoDistanceFields.response = '';
        if (geoDistance) {
          if (geoDistance.distance) {
            if (geoDistance.distance.value) {
              this.geoDistanceFields.response = geoDistance.distance.value?.value + ' ';
            }
            if (geoDistance.distance.unit) {
              this.geoDistanceFields.response += DISTANCE_UNITS_NAMES[DISTANCE_UNITS.indexOf(geoDistance.distance.unit)];
            }
          }
          if (geoDistance.drivingDuration) {
            this.geoDistanceFields.response += '\n' + 'Driving duration: ' + Formatter.formatDurationProto(geoDistance.drivingDuration);
          }
          if (geoDistance.drivingDurationInTraffic) {
            this.geoDistanceFields.response += '\n' + 'Driving duration in traffic: ' + Formatter.formatDurationProto(geoDistance.drivingDurationInTraffic);
          }
        }
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        if (this.sessionService.handleAuthenticationError(error)) {
          return;
        }
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_response'));
      }
    );
  }

  public onDeliveryTipChange(money: Money | null | undefined) {
    this.deliveryQuoteFields.deliveryTip = money;
  }

  public getDeliveryQuote(actionObserver: ActionObserver): void {
    this.deliveryQuoteFields.response = '';
    const address = ProtoUtil.createSimpleAddressProto(this.deliveryQuoteFields.address.value);
    const phoneNumber = ProtoUtil.createSimplePhoneNumberProto(this.deliveryQuoteFields.phoneNumber.value);
    this.backendService.getDeliveryQuote(
      this.deliveryQuoteFields.locationId.value,
      address,
      this.deliveryQuoteFields.email.value,
      phoneNumber,
      new proto.waiternow.common.DateTimeProto(),
      this.deliveryQuoteFields.deliveryTip ? ProtoUtil.pickerMoneyToMoneyProto(this.deliveryQuoteFields.deliveryTip) : new proto.waiternow.common.MoneyProto(),
      /* onSuccess= */ deliveryQuoteProto => {
        this.deliveryQuoteFields.response = '';
        if (deliveryQuoteProto?.deliveryCarrier == proto.waiternow.common.DeliveryCarrier.SELF) {
          this.deliveryQuoteFields.response += 'Delivery carrier: Self \n';
        } else if(deliveryQuoteProto?.deliveryCarrier == proto.waiternow.common.DeliveryCarrier.DOORDASH) {
          this.deliveryQuoteFields.response += 'Delivery carrier: Doordash \n';
        }
        if (deliveryQuoteProto?.distance) {
          this.deliveryQuoteFields.response += 'Distance: ' + ProtoUtil.protoToString(deliveryQuoteProto?.distance) + '\n';
        }
        if (deliveryQuoteProto?.deliveryTip) {
          this.deliveryQuoteFields.response += 'Delivery Tip: ' + Formatter.formatMoneyIncludeCurrency(deliveryQuoteProto?.deliveryTip) + '\n';
        }
        this.deliveryQuoteFields.response += 'Fee: ' + Formatter.formatMoneyIncludeCurrency(deliveryQuoteProto?.fee) + '\n';
        this.deliveryQuoteFields.response += 'Quote Expiration: ' + Formatter.formatDurationProto(deliveryQuoteProto?.quoteExpiration) + '\n';
        if (deliveryQuoteProto?.requestedPickupTime) {
          this.deliveryQuoteFields.response += 'Requested Pickup time: ' + Formatter.formatTimestampProto(deliveryQuoteProto?.requestedPickupTime) + '\n';
        }
        if (deliveryQuoteProto?.requestedDropoffTime) {
          this.deliveryQuoteFields.response += 'Requested Dropoff time: ' + Formatter.formatTimestampProto(deliveryQuoteProto?.requestedDropoffTime) + '\n';
        }
        if (deliveryQuoteProto?.estimatedPickupTime) {
          this.deliveryQuoteFields.response += 'Estimated Pickup time: ' + Formatter.formatTimestampProto(deliveryQuoteProto?.estimatedPickupTime) + '\n';
        }
        if (deliveryQuoteProto?.estimatedDropoffTime) {
          this.deliveryQuoteFields.response += 'Estimated Dropoff time: ' + Formatter.formatTimestampProto(deliveryQuoteProto?.estimatedDropoffTime) + '\n';
        }
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        if (this.sessionService.handleAuthenticationError(error)) {
          return;
        }
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_response'));
      }
    );
  }
}
