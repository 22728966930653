import { Injectable } from '@angular/core';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentUtil } from '../util/environment-util';
import { SessionService } from './session.service';
import { AppError } from '../../general/util/error';
import { Function, Runnable, Consumer } from 'src/app/general/interfaces/functions';
import { BusinessOrLocationId } from '../util/util';
import { LoggingService } from 'src/app/general/services/logging.service';
import * as proto from 'src/proto/compiled-protos';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(
      private httpClient: HttpClient,
      private sessionService: SessionService,
      private loggingService: LoggingService) {
  }

  public sendEmailToContact(fromEmail: string, subject: string, body:string, onSuccess: Runnable, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SendEmailActionProto.Request();
    request.from = new proto.waiternow.common.EmailAddressProto();
    request.from.email = fromEmail;
    request.subject = subject;
    request.body = body;
    request.to = proto.waiternow.common.EmailRecipient.CONTACT;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/common/email/send'),
      request,
      protoRequest => proto.waiternow.common.SendEmailActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SendEmailActionProto.Response.decode(uint8ArrayProtoResponse)
     )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public signIn(email: string, password: string, onSuccess: Consumer<proto.waiternow.common.SignInActionProto.Response>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SignInActionProto.Request();
    request.email = email;
    request.password = password;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/signin'),
      request,
      protoRequest => proto.waiternow.common.SignInActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SignInActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findBusinessesByUserId(userId: string, onSuccess: Consumer<proto.waiternow.common.IUserBusinessesProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindBusinessesByUserActionProto.Request();
    request.userId = userId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/business/findbyuser'),
      request,
      protoRequest => proto.waiternow.common.FindBusinessesByUserActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindBusinessesByUserActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.userBusinesses))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }


  public findBusinessesByUserEmail(email: string, onSuccess: Consumer<proto.waiternow.common.IUserBusinessesProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindBusinessesByUserActionProto.Request();
    request.userEmail = email;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/business/findbyuser'),
      request,
      protoRequest => proto.waiternow.common.FindBusinessesByUserActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindBusinessesByUserActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.userBusinesses))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findBusinessesByNameReturnObservable(businessName: string, continuationToken?: string | null | undefined): Observable<proto.waiternow.common.IBusinessesProto | null | undefined> {
    const request = new proto.waiternow.common.FindBusinessesByNameActionProto.Request();
    request.businessName = businessName;
    if (continuationToken) {
      request.continuationToken = continuationToken;
    }
    return this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/business/find_by_name'),
      request,
      protoRequest => proto.waiternow.common.FindBusinessesByNameActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindBusinessesByNameActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.businesses))
    );
  }

  public findBusinessesByName(businessName: string, continuationToken: string | null | undefined, onSuccess: Consumer<proto.waiternow.common.IBusinessesProto | null | undefined>, onError: Consumer<AppError>): void {
    this.findBusinessesByNameReturnObservable(businessName, continuationToken)
      .subscribe(
        {
          next: data => onSuccess(data),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  public findMetrics(periodType: proto.waiternow.common.PeriodType, onSuccess: Consumer<proto.waiternow.common.IMetricsProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindMetricsActionProto.Request();
    request.periodType = periodType;
    request.sortOrder = proto.waiternow.common.SortOrder.DESCENDING;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/metrics/find'),
      request,
      protoRequest => proto.waiternow.common.FindMetricsActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindMetricsActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.metrics))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findLocationMetrics(locationId: string, periodType: proto.waiternow.common.PeriodType, onSuccess: Consumer<proto.waiternow.common.ILocationMetricsProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindLocationMetricsActionProto.Request();
    request.locationId = locationId;
    request.periodType = periodType;
    request.sortOrder = proto.waiternow.common.SortOrder.DESCENDING;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/metrics/find'),
      request,
      protoRequest => proto.waiternow.common.FindLocationMetricsActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindLocationMetricsActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.metrics))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public updateBusinessLogo(businessId: string, file: File, onSuccess: Runnable, onError: Consumer<AppError>): void {
    this.httpPostWithFile(EnvironmentUtil.resolveBackendUrl('/service/user/business/logo/update?business-id=' + businessId), 'logo', file)
      .subscribe(
        {
          next: response => onSuccess(),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  public updateBusinessHeaderForLandscapeScreen(businessId: string, file: File, onSuccess: Runnable, onError: Consumer<AppError>): void {
    this.httpPostWithFile(EnvironmentUtil.resolveBackendUrl('/service/user/business/header/landscape/update?business-id=' + businessId), 'header', file)
      .subscribe(
        {
          next: response => onSuccess(),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  public updateBusinessHeaderForPortraitScreen(businessId: string, file: File, onSuccess: Runnable, onError: Consumer<AppError>): void {
    this.httpPostWithFile(EnvironmentUtil.resolveBackendUrl('/service/user/business/header/portrait/update?business-id=' + businessId), 'header', file)
      .subscribe(
        {
          next: response => onSuccess(),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  public findBusinessUsers(businessId: string, onSuccess: Consumer<proto.waiternow.common.IBusinessUsersProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindUsersByBusinessActionProto.Request();
    request.businessId = businessId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/findbybusiness'),
      request,
      protoRequest => proto.waiternow.common.FindUsersByBusinessActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindUsersByBusinessActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.businessUsers))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findBusinessLocations(businessId: string, onSuccess: Consumer<proto.waiternow.common.ILocationsProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindLocationsByBusinessActionProto.Request();
    request.businessId = businessId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/find_by_business'),
      request,
      protoRequest => proto.waiternow.common.FindLocationsByBusinessActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindLocationsByBusinessActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.locations))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getBusiness(businessId: string, onSuccess: Consumer<proto.waiternow.common.IBusinessProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetBusinessActionProto.Request();
    request.businessId = businessId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/business/get'),
      request,
      protoRequest => proto.waiternow.common.GetBusinessActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetBusinessActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.business))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getLocation(locationId: string, onSuccess: Consumer<proto.waiternow.common.ILocationProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetLocationActionProto.Request();
    request.locationId = locationId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/get'),
      request,
      protoRequest => proto.waiternow.common.GetLocationActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetLocationActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.location))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public createBusiness(
      business: proto.waiternow.common.BusinessProto,
      ownerUserId: string,
      onSuccess: Consumer<proto.waiternow.common.IBusinessProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.CreateBusinessActionProto.Request();
    request.business = business;
    request.ownerUserId = ownerUserId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/business/create'),
      request,
      protoRequest => proto.waiternow.common.CreateBusinessActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.CreateBusinessActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.business))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public updateBusiness(
      business: proto.waiternow.common.BusinessProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdateBusinessActionProto.Request.RemovableField>,
      locationSettingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.UpdateBusinessActionProto.Request();
    request.business = business;
    request.fieldsToRemove = fieldsToRemove;
    request.locationSettingsFieldsToRemove = locationSettingsFieldsToRemove;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/business/update'),
      request,
      protoRequest => proto.waiternow.common.UpdateBusinessActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.UpdateBusinessActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public createLocation(
      location: proto.waiternow.common.LocationProto,
      onSuccess: Consumer<proto.waiternow.common.ILocationProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.CreateLocationActionProto.Request();
    request.location = location;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/create'),
      request,
      protoRequest => proto.waiternow.common.CreateLocationActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.CreateLocationActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.location))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public updateLocation(
      location: proto.waiternow.common.LocationProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.RemovableField>,
      settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.UpdateLocationActionProto.Request();
    request.location = location;
    request.fieldsToRemove = fieldsToRemove;
    request.settingsFieldsToRemove = settingsFieldsToRemove;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/update'),
      request,
      protoRequest => proto.waiternow.common.UpdateLocationActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.UpdateLocationActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public validateAddress(address: proto.waiternow.common.AddressProto, onSuccess: Consumer<boolean>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.ValidateAddressActionProto.Request();
    request.address = address;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/address/validate'),
      request,
      protoRequest => proto.waiternow.common.ValidateAddressActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.ValidateAddressActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.isAddressValid))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public calculateGeoDistance(
      from: proto.waiternow.common.AddressProto,
      to: proto.waiternow.common.AddressProto,
      onSuccess: Consumer<proto.waiternow.common.IGeoDistanceProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.CalculateGeoDistanceActionProto.Request();
    request.from = from;
    request.to = to;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/geo/calculate_distance'),
      request,
      protoRequest => proto.waiternow.common.CalculateGeoDistanceActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.CalculateGeoDistanceActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.geoDistance))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public enrollLocationInDelivery(
      locationId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.EnrollLocationInDeliveryActionProto.Request();
    request.locationId = locationId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/delivery/enroll'),
      request,
      protoRequest => proto.waiternow.common.EnrollLocationInDeliveryActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.EnrollLocationInDeliveryActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getDeliveryQuote(
      locationId: string,
      deliveryAddress: proto.waiternow.common.AddressProto,
      email: string,
      phoneNumber: proto.waiternow.common.PhoneNumberProto,
      orderTime: proto.waiternow.common.DateTimeProto,
      deliveryTip: proto.waiternow.common.MoneyProto,
      onSuccess: Consumer<proto.waiternow.common.IDeliveryProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetDeliveryQuoteActionProto.Request();
    request.locationId = locationId;
    request.deliveryAddress = deliveryAddress;
    request.email = email;
    request.phoneNumber = phoneNumber;
    request.orderTime = orderTime;
    request.deliveryTip = deliveryTip;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/consumer/delivery/quote/get'),
      request,
      protoRequest => proto.waiternow.common.GetDeliveryQuoteActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetDeliveryQuoteActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.delivery))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findLocationDevices(locationId: string, onSuccess: Consumer<proto.waiternow.common.ILocationDevicesProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindDevicesByLocationActionProto.Request();
    request.locationId = locationId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/device/findbylocation'),
      request,
      protoRequest => proto.waiternow.common.FindDevicesByLocationActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindDevicesByLocationActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.locationDevices))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public terminateDeviceSessionRequest(deviceId: string, onSuccess: Runnable, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.TerminateDeviceSessionRequestActionProto.Request();
    request.deviceId = deviceId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/device/terminate_session'),
      request,
      protoRequest => proto.waiternow.common.TerminateDeviceSessionRequestActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.TerminateDeviceSessionRequestActionProto.Response.decode(uint8ArrayProtoResponse)
     )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findLocationPaidOrdersReturnObservable(locationId: string, continuationToken?: string | null | undefined): Observable<proto.waiternow.common.ILocationOrdersProto | null | undefined> {
    const request = new proto.waiternow.common.FindPaidOrdersActionProto.Request();
    request.locationId = locationId;
    request.sortOrder = proto.waiternow.common.SortOrder.DESCENDING;
    if (continuationToken) {
      request.continuationToken = continuationToken;
    }
    return this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/findpaid'),
      request,
      protoRequest => proto.waiternow.common.FindPaidOrdersActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindPaidOrdersActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.locationOrders))
    );
  }

  public findLocationPaidOrders(locationId: string, continuationToken: string | null | undefined, onSuccess: Consumer<proto.waiternow.common.ILocationOrdersProto | null | undefined>, onError: Consumer<AppError>): void {
    this.findLocationPaidOrdersReturnObservable(locationId, continuationToken)
      .subscribe(
        {
          next: data => onSuccess(data),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  public findLocationRefundedOrdersReturnObservable(locationId: string, continuationToken?: string | null | undefined): Observable<proto.waiternow.common.ILocationOrdersProto | null | undefined> {
    const request = new proto.waiternow.common.FindRefundedOrdersActionProto.Request();
    request.locationId = locationId;
    request.sortOrder = proto.waiternow.common.SortOrder.DESCENDING;
    if (continuationToken) {
      request.continuationToken = continuationToken;
    }
    return this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/find_refunded'),
      request,
      protoRequest => proto.waiternow.common.FindRefundedOrdersActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindRefundedOrdersActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.locationOrders))
    );
  }

  public findLocationDisputedOrdersReturnObservable(locationId: string, continuationToken?: string | null | undefined): Observable<proto.waiternow.common.ILocationOrdersProto | null | undefined> {
    const request = new proto.waiternow.common.FindDisputedOrdersActionProto.Request();
    request.locationId = locationId;
    request.sortOrder = proto.waiternow.common.SortOrder.DESCENDING;
    if (continuationToken) {
      request.continuationToken = continuationToken;
    }
    return this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/find_disputed'),
      request,
      protoRequest => proto.waiternow.common.FindDisputedOrdersActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindDisputedOrdersActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.locationOrders))
    );
  }

  public findDisputedOrdersReturnObservable(continuationToken?: string | null | undefined): Observable<proto.waiternow.common.IOrdersProto | null | undefined> {
    const request = new proto.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request();
    if (continuationToken) {
      request.continuationToken = continuationToken;
    }
    return this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/find_disputed_for_all_locations'),
      request,
      protoRequest => proto.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.orders))
    );
  }

  public findUnackedAndPaidOrdersReturnObservable(continuationToken?: string | null | undefined): Observable<proto.waiternow.common.IOrdersProto | null | undefined> {
    const request = new proto.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request();
    request.sortOrder = proto.waiternow.common.SortOrder.DESCENDING;
    if (continuationToken) {
      request.continuationToken = continuationToken;
    }
    return this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/find_unacked_and_paid_for_all_locations'),
      request,
      protoRequest => proto.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.orders))
    );
  }

  public findUnackedAndPaidOrders(continuationToken: string | null | undefined, onSuccess: Consumer<proto.waiternow.common.IOrdersProto | null | undefined>, onError: Consumer<AppError>): void {
    this.findUnackedAndPaidOrdersReturnObservable(continuationToken)
      .subscribe(
        {
          next: data => onSuccess(data),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  public createUser(
      user: proto.waiternow.common.UserProto,
      emailVerificationCode: string,
      password: string,
      phoneNumberVerificationCode: string,
      onSuccess: Consumer<proto.waiternow.common.SignUpActionProto.IResponse>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SignUpActionProto.Request();
    request.user = user;
    request.emailVerificationCode = emailVerificationCode;
    request.password = password;
    request.phoneNumberVerificationCode = phoneNumberVerificationCode;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/signup'),
      request,
      protoRequest => proto.waiternow.common.SignUpActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SignUpActionProto.Response.decode(uint8ArrayProtoResponse)
     )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public sendEmailVerificationCode(email: string, onSuccess: Runnable, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SendEmailVerificationCodeActionProto.Request();
    request.email = email;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/send_email_verification_code'),
      request,
      protoRequest => proto.waiternow.common.SendEmailVerificationCodeActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SendEmailVerificationCodeActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getEmailVerificationCode(email: string, onSuccess: Consumer<string>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetEmailVerificationCodeActionProto.Request();
    request.email = email;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/verification_code/email/get'),
      request,
      protoRequest => proto.waiternow.common.GetEmailVerificationCodeActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetEmailVerificationCodeActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.verificationCode))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public validateEmailCode(email: string, verificationCode: string, onSuccess: Consumer<proto.waiternow.common.ValidateEmailVerificationCodeActionProto.IResponse>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.ValidateEmailVerificationCodeActionProto.Request();
    request.email = email;
    request.verificationCode = verificationCode;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/validate_email_verification_code'),
      request,
      protoRequest => proto.waiternow.common.ValidateEmailVerificationCodeActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.ValidateEmailVerificationCodeActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public sendPhoneNumberVerificationCode(phoneNumber: proto.waiternow.common.PhoneNumberProto, onSuccess: Runnable, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request();
    request.phoneNumber = phoneNumber;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/send_phone_number_verification_code'),
      request,
      protoRequest => proto.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getPhoneNumberVerificationCode(phoneNumber: proto.waiternow.common.PhoneNumberProto, onSuccess: Consumer<string>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request();
    request.phoneNumber = phoneNumber;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/verification_code/phone_number/get'),
      request,
      protoRequest => proto.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.verificationCode))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public validatePhoneNumberCode(
      phoneNumber: proto.waiternow.common.PhoneNumberProto,
      verificationCode: string,
      onSuccess: Consumer<proto.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IResponse>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request();
    request.phoneNumber = phoneNumber;
    request.verificationCode = verificationCode;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/validate_phone_number_verification_code'),
      request,
      protoRequest => proto.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getUser(
      userId: string,
      onSuccess: Consumer<proto.waiternow.common.IUserProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetUserActionProto.Request();
    request.userId = userId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/user/get'),
      request,
      protoRequest => proto.waiternow.common.GetUserActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetUserActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.user))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findUserByEmail(
      userEmail: string,
      onSuccess: Consumer<proto.waiternow.common.IUserProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindUserActionProto.Request();
    request.userEmail = userEmail;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/user/find'),
      request,
      protoRequest => proto.waiternow.common.FindUserActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindUserActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.user))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public updateUser(
      user: proto.waiternow.common.UserProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdateUserActionProto.Request.RemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.UpdateUserActionProto.Request();
    request.user = user;
    request.fieldsToRemove = fieldsToRemove;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/update'),
      request,
      protoRequest => proto.waiternow.common.UpdateUserActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.UpdateUserActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public deleteUser(
      userId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.DeleteUserActionProto.Request();
    request.userId = userId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/user/delete'),
      request,
      protoRequest => proto.waiternow.common.DeleteUserActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.DeleteUserActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public deleteBusiness(
      businessId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.DeleteBusinessActionProto.Request();
    request.businessId = businessId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/business/delete'),
      request,
      protoRequest => proto.waiternow.common.DeleteBusinessActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.DeleteBusinessActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public deleteLocation(
      locationId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.DeleteLocationActionProto.Request();
    request.locationId = locationId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/delete'),
      request,
      protoRequest => proto.waiternow.common.DeleteLocationActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.DeleteLocationActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getOrder(
      orderId: string,
      onSuccess: Consumer<proto.waiternow.common.IOrderProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetOrderActionProto.Request();
    request.orderId = orderId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/get'),
      request,
      protoRequest => proto.waiternow.common.GetOrderActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetOrderActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.order))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public refundOrder(
      orderId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.RefundPaidOrderActionProto.Request();
    request.orderId = orderId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/refund'),
      request,
      protoRequest => proto.waiternow.common.RefundPaidOrderActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.RefundPaidOrderActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public markOrderAsDisputed(
      orderId: string,
      disputeDate: proto.waiternow.common.IDateProto,
      reason: string | undefined,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.MarkOrderAsDisputedActionProto.Request();
    request.orderId = orderId;
    request.disputeDate = disputeDate;
    if (reason) {
      request.reason = reason;
    }

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/mark_disputed'),
      request,
      protoRequest => proto.waiternow.common.MarkOrderAsDisputedActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.MarkOrderAsDisputedActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findLocationPointsOfService(locationId: string, onSuccess: Consumer<proto.waiternow.common.ILocationPointsOfServiceProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindPointsOfServiceByLocationActionProto.Request();
    request.locationId = locationId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/pointofservice/findbylocation'),
      request,
      protoRequest => proto.waiternow.common.FindPointsOfServiceByLocationActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindPointsOfServiceByLocationActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.locationPointsOfService))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public deletePointOfService(
      pointOfServiceId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.DeletePointOfServiceActionProto.Request();
    request.pointOfServiceId = pointOfServiceId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/pointofservice/delete'),
      request,
      protoRequest => proto.waiternow.common.DeletePointOfServiceActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.DeletePointOfServiceActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getPointOfService(pointOfServiceId: string, onSuccess: Consumer<proto.waiternow.common.IPointOfServiceProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetPointOfServiceActionProto.Request();
    request.pointOfServiceId = pointOfServiceId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/pointofservice/get'),
      request,
      protoRequest => proto.waiternow.common.GetPointOfServiceActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetPointOfServiceActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.pointOfService))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public createPointOfService(
      pointOfService: proto.waiternow.common.PointOfServiceProto,
      onSuccess: Consumer<proto.waiternow.common.IPointOfServiceProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.CreatePointOfServiceActionProto.Request();
    request.pointOfService = pointOfService;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/pointofservice/create'),
      request,
      protoRequest => proto.waiternow.common.CreatePointOfServiceActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.CreatePointOfServiceActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.pointOfService))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public updatePointOfService(
      pointOfService: proto.waiternow.common.PointOfServiceProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.UpdatePointOfServiceActionProto.Request();
    request.pointOfService = pointOfService;
    request.fieldsToRemove = fieldsToRemove;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/pointofservice/update'),
      request,
      protoRequest => proto.waiternow.common.UpdatePointOfServiceActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.UpdatePointOfServiceActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public updateUserEmail(
      userId: string,
      newEmail: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SuperuserUpdateActionProto.Request();
    request.userUpdate = new proto.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto();
    request.userUpdate.userId = userId;
    request.userUpdate.newEmail = newEmail;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/update'),
      request,
      protoRequest => proto.waiternow.common.SuperuserUpdateActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SuperuserUpdateActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public updateUserPassword(
      userId: string,
      newPassword: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SuperuserUpdateActionProto.Request();
    request.userUpdate = new proto.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto();
    request.userUpdate.userId = userId;
    request.userUpdate.newPassword = newPassword;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/admin/update'),
      request,
      protoRequest => proto.waiternow.common.SuperuserUpdateActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SuperuserUpdateActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public deleteCampaign(
      campaignId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.DeleteCampaignActionProto.Request();
    request.campaignId = campaignId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/campaign/delete'),
      request,
      protoRequest => proto.waiternow.common.DeleteCampaignActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.DeleteCampaignActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findCampaigns(
      businessOrLocationId: BusinessOrLocationId,
      onSuccess: Consumer<proto.waiternow.common.ICampaignsProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindCampaignsByScopeActionProto.Request();
    if (businessOrLocationId.businessId) {
      request.businessId = businessOrLocationId.businessId;
    } else {
      request.locationId = businessOrLocationId.locationId;
    }
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/campaign/find_by_scope'),
      request,
      protoRequest => proto.waiternow.common.FindCampaignsByScopeActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindCampaignsByScopeActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.campaigns))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getCampaign(campaignId: string, onSuccess: Consumer<proto.waiternow.common.ICampaignProto | null | undefined>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetCampaignActionProto.Request();
    request.campaignId = campaignId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/campaign/get'),
      request,
      protoRequest => proto.waiternow.common.GetCampaignActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetCampaignActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.campaign))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public createCampaign(
      campaign: proto.waiternow.common.CampaignProto,
      onSuccess: Consumer<proto.waiternow.common.ICampaignProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.CreateCampaignActionProto.Request();
    request.campaign = campaign;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/campaign/create'),
      request,
      protoRequest => proto.waiternow.common.CreateCampaignActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.CreateCampaignActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.campaign))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public updateCampaign(
      campaign: proto.waiternow.common.CampaignProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdateCampaignActionProto.Request.RemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.UpdateCampaignActionProto.Request();
    request.campaign = campaign;
    request.fieldsToRemove = fieldsToRemove;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/campaign/update'),
      request,
      protoRequest => proto.waiternow.common.UpdateCampaignActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.UpdateCampaignActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findDeviceOrdersReturnObservable(deviceId: string, continuationToken?: string | null | undefined): Observable<proto.waiternow.common.IDeviceOrdersProto | null | undefined> {
    const request = new proto.waiternow.common.FindDeviceOrdersActionProto.Request();
    request.deviceId = deviceId;
    if (continuationToken) {
      request.continuationToken = continuationToken;
    }
    return this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/consumer/order/findbydevice'),
      request,
      protoRequest => proto.waiternow.common.FindDeviceOrdersActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindDeviceOrdersActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.deviceOrders))
    );
  }

  public findDeviceOrdersActionProto(deviceId: string, continuationToken: string | null | undefined, onSuccess: Consumer<proto.waiternow.common.IDeviceOrdersProto | null | undefined>, onError: Consumer<AppError>): void {
    this.findLocationPaidOrdersReturnObservable(deviceId, continuationToken)
      .subscribe(
        {
          next: data => onSuccess(data),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  public verifyPaymentsEnrollment(
      locationId: string,
      onSuccess: Consumer<boolean>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request();
    request.locationId = locationId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/payment/marketplace/enroll/verify'),
      request,
      protoRequest => proto.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.isEnrolled))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public checkIn(
      pointOfServiceId: string,
      onSuccess: Consumer<proto.waiternow.common.ICheckInDataProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.CheckInActionProto.Request();
    request.pointOfServiceId = pointOfServiceId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/consumer/checkin'),
      request,
      protoRequest => proto.waiternow.common.CheckInActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.CheckInActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.checkInData))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public denormalizeStructuredMenu(
      menu: proto.waiternow.common.StructuredMenuSpecProto,
      onSuccess: Consumer<proto.waiternow.common.IStructuredMenuProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.DenormalizeStructuredMenuActionProto.Request();
    request.menu = menu;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/menu/structured/denormalize'),
      request,
      protoRequest => proto.waiternow.common.DenormalizeStructuredMenuActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.DenormalizeStructuredMenuActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.menu))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getDraftOfStructuredMenu(
      businessOrLocationId: BusinessOrLocationId,
      onSuccess: Consumer<proto.waiternow.common.GetDraftOfStructuredMenuActionProto.Response | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetDraftOfStructuredMenuActionProto.Request();
    if (businessOrLocationId.businessId) {
      request.businessId = businessOrLocationId.businessId;
    } else {
      request.locationId = businessOrLocationId.locationId;
    }

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/menu/structured/getdraft'),
      request,
      protoRequest => proto.waiternow.common.GetDraftOfStructuredMenuActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetDraftOfStructuredMenuActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public saveDraftOfStructuredMenu(
      businessOrLocationId: BusinessOrLocationId,
      menu: proto.waiternow.common.StructuredMenuSpecProto,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SaveDraftOfStructuredMenuActionProto.Request();
    if (businessOrLocationId.businessId) {
      request.businessId = businessOrLocationId.businessId;
    } else {
      request.locationId = businessOrLocationId.locationId;
    }
    request.menu = menu;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/menu/structured/savedraft'),
      request,
      protoRequest => proto.waiternow.common.SaveDraftOfStructuredMenuActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SaveDraftOfStructuredMenuActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public publishStructuredMenu(
      businessOrLocationId: BusinessOrLocationId,
      menu: proto.waiternow.common.StructuredMenuSpecProto,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.PublishStructuredMenuActionProto.Request();
    if (businessOrLocationId.businessId) {
      request.businessId = businessOrLocationId.businessId;
    } else {
      request.locationId = businessOrLocationId.locationId;
    }
    request.menu = menu;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/menu/structured/publish'),
      request,
      protoRequest => proto.waiternow.common.PublishStructuredMenuActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.PublishStructuredMenuActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public removeDraftOfStructuredMenu(
      businessOrLocationId: BusinessOrLocationId,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request();
    if (businessOrLocationId.businessId) {
      request.businessId = businessOrLocationId.businessId;
    } else {
      request.locationId = businessOrLocationId.locationId;
    }

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/menu/structured/removedraft'),
      request,
      protoRequest => proto.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public removeStructuredMenu(
      businessOrLocationId: BusinessOrLocationId,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.RemoveStructuredMenuActionProto.Request();
    if (businessOrLocationId.businessId) {
      request.businessId = businessOrLocationId.businessId;
    } else {
      request.locationId = businessOrLocationId.locationId;
    }

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/menu/structured/remove'),
      request,
      protoRequest => proto.waiternow.common.RemoveStructuredMenuActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.RemoveStructuredMenuActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public addImageToStructuredMenu(
      businessOrLocationId: BusinessOrLocationId,
      file: File,
      onSuccess: Consumer<proto.waiternow.common.IImageProto | null | undefined>,
      onError: Consumer<AppError>): void {
    let actionUrl = '/service/user/menu/structured/image/add/multi_part_form_request';
    if (businessOrLocationId.businessId) {
      actionUrl += '?business-id=' + businessOrLocationId.businessId;
    } else {
      actionUrl += '?location-id=' + businessOrLocationId.locationId;
    }

    this.httpPostWithFile(EnvironmentUtil.resolveBackendUrl(actionUrl), 'image', file)
    .pipe(
      map(
        (arrayBufferResponse: ArrayBuffer) => {
          return proto.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response.decode(
            new Uint8Array(arrayBufferResponse))
      }))
      .pipe(
        map(
          this.extractDataAndMapOperationStatusErrorToObservableError(
            /* extractOperationStatus= */ response => response.operationStatus,
            /* extractData= */ response => response.image))
      )
      .subscribe(
        {
          next: data => onSuccess(data),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  private extractDataAndMapOperationStatusErrorToObservableError<R, D>(
      extractOperationStatus: (response: R) => proto.waiternow.common.IOperationStatusProto | null | undefined,
      extractData: (response: R) => D): (response: R) => D {
    return response => {
      const operationstatus = extractOperationStatus(response);
      if (operationstatus && operationstatus.isFailure) {
        const errorMessage = 'Server error:'
            + '\n  Error code: '+ operationstatus.errorCode
            + '.\n  Message: ' + operationstatus.errorMessage;
        this.loggingService.logError(errorMessage)
        throw new AppError(
          /* message= */ errorMessage,
          /* cause= */ undefined,
          /* httpErrorCode= */ undefined,
          /* serverErrorCode= */ operationstatus.errorCode);
      }
      return extractData(response);
    };
  }

  private httpPostWithBinaryRequestAndBinaryResponse(url: string, request: ArrayBuffer): Observable<ArrayBuffer> {
    let httpHeaders = new HttpHeaders();
    if (this.sessionService.getAuthToken()) {
      httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    }
    const observableHttpResponse = this.httpClient.post(url, request, {headers: httpHeaders, responseType: 'arraybuffer'})
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
    return observableHttpResponse;
  }

  private httpPostWithProtoRequestAndProtoResponse<T, R>(
      url: string,
      request:T,
      encodeRequest: Function<T, Uint8Array>,
      decodeResponse: Function<Uint8Array, R>): Observable<R> {
    const uint8Array = encodeRequest(request);
    const arrayBufferRequest = this.uint8ArrayToArrayBuffer(uint8Array);
    return this.httpPostWithBinaryRequestAndBinaryResponse(url, arrayBufferRequest)
    .pipe(
      map(
        (arrayBufferResponse: ArrayBuffer) => {
          return decodeResponse(new Uint8Array(arrayBufferResponse));
      }));
  }

  private httpPostWithFile(url: string, fileFieldName: string, file: File): Observable<ArrayBuffer> {
    let httpHeaders = new HttpHeaders();
    if (this.sessionService.getAuthToken()) {
      httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    }
    const formData = new FormData();
    formData.append(fileFieldName, file);
    const observableHttpResponse = this.httpClient.post(url, formData, {headers: httpHeaders, responseType: 'arraybuffer'})
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
    return observableHttpResponse;
  }

  public httpGetAndOpenInNewWindow(url: string, onSuccess: Runnable, onError: Consumer<AppError>): void {
    let httpHeaders = new HttpHeaders();
    if (this.sessionService.getAuthToken()) {
      httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    }
    this.httpClient.get(url, {headers: httpHeaders, responseType: 'arraybuffer'})
      .subscribe(
      {
        next: (arrayBufferResponse: ArrayBuffer) => {
          const downloadLink: string = window.URL.createObjectURL(new Blob([arrayBufferResponse]));
          const windowProxy = window.open(downloadLink, "_blank");
          if (windowProxy) {
            windowProxy.focus();
          }
          onSuccess();
        },
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public httpPostAndOpenInNewWindow(
      url: string,
      params: {[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;},
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    let httpHeaders = new HttpHeaders();
    if (this.sessionService.getAuthToken()) {
      httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    }
    this.httpClient.post(url, params, {headers: httpHeaders, responseType: 'arraybuffer'})
      .subscribe(
      {
        next: (arrayBufferResponse: ArrayBuffer) => {
          const downloadLink: string = window.URL.createObjectURL(new Blob([arrayBufferResponse]));
          const windowProxy = window.open(downloadLink, "_blank");
          if (windowProxy) {
            windowProxy.focus();
          }
          onSuccess();
        },
        error: error => onError(this.toAppError(error))
      }
    );
  }

  private uint8ArrayToArrayBuffer(uint8Array: Uint8Array): ArrayBuffer {
    const arrayBuffer = new ArrayBuffer(uint8Array.length);
    const auxbuffer = new Uint8Array(arrayBuffer);
    for (let i=0; i < uint8Array.length; i++) {
      auxbuffer[i] = uint8Array[i];
    }
    return arrayBuffer;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      this.loggingService.logError('Client error: ', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.loggingService.logError(
        `HTTP error code: ${error.status}, body: `, error.error);
    }
    // Return an observable with a user-facing error message.
    // return throwError(() => new Error('Something bad happened; please try again later.'));
    return throwError(() => new AppError(/* message= */ 'HTTP error', /* cause= */ error, /* httpErrorCode= */ error.status));
  }

  private toAppError(error: any): AppError {
    if (error instanceof AppError) {
      return error;
    }
    return new AppError(/* message= */ 'Unknown error', /* cause= */ error);
  }
}
