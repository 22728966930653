<mat-accordion multi>
    <!-- ------------------------------------------------------------------ -->
    <!-- Alerts -->
    <!-- ------------------------------------------------------------------ -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title *ngIf="businessId">
                {{ 'location_settings_alerts_global' | translate }}
            </mat-panel-title>
            <mat-panel-title *ngIf="locationId">
                {{ 'location_settings_alerts' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'email' | translate }}</mat-label>
                <input matInput placeholder="email@example.com" [formControl]="alertConfigFields.email">
                <mat-error *ngIf="alertConfigFields.email.invalid">{{formService.getFieldErrorMessage(alertConfigFields.email)}}</mat-error>
            </mat-form-field>
        </div>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'phone_number' | translate }}</mat-label>
                <input matInput [formControl]="alertConfigFields.phoneNumber">
                <mat-error *ngIf="alertConfigFields.phoneNumber.invalid">{{formService.getFieldErrorMessage(alertConfigFields.phoneNumber)}}</mat-error>
            </mat-form-field>
        </div>
        <div appFieldContainer>
            <app-action [text]="'save' | translate"
                [disabled]="alertConfigFields.email.invalid || alertConfigFields.phoneNumber.invalid"
                (onAsyncExecute)="saveAlerts($event)">
            </app-action>
        </div>
    </mat-expansion-panel>
    <!-- ------------------------------------------------------------------ -->
    <!-- Enabled Features -->
    <!-- ------------------------------------------------------------------ -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title *ngIf="businessId">
                {{ 'location_settings_enabled_features_global' | translate }}
            </mat-panel-title>
            <mat-panel-title *ngIf="locationId">
                {{ 'location_settings_enabled_features' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.pokeWaiter">
                {{ 'poke_waiter' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.structuredMenu">
                {{ 'structured_menu' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.imageMenu">
                {{ 'image_menu' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.messageWaiter">
                {{ 'message_waiter' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.askForCheck">
                {{ 'ask_for_check' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.specialInstructionsForMenuItem">
                {{ 'special_instructions_for_menu_item' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.orderForLater">
                {{ 'order_for_later' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.orderForLaterDay">
                {{ 'order_for_later_day' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.onlineTips">
                {{ 'online_tips' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.onsiteTips">
                {{ 'onsite_tips' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.delivery">
                {{ 'delivery' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.chargeCreditCardFeeToConsumer">
                {{ 'charge_credit_card_fee_to_conmsumer' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.alcoholForOnlinePickup">
                {{ 'alcohol_for_online_pickup' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.alcoholForDelivery">
                {{ 'alcohol_for_delivery' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.printOrderAndReceipt">
                {{ 'print_order_and_receipt' | translate }}
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="enabledFeaturesFields.isSmsOnNewOrdersForBusinessWebEnabled">
                {{ 'is_sms_on_new_orders_for_business_web_enabled' | translate }}
            </mat-checkbox>
        </div>
        <div appFieldContainer>
            <app-action [text]="'save' | translate" (onAsyncExecute)="saveEnabledFeatures($event)"></app-action>
        </div>
    </mat-expansion-panel>
    <!-- ------------------------------------------------------------------ -->
    <!-- View -->
    <!-- ------------------------------------------------------------------ -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title *ngIf="businessId">
                {{ 'location_settings_view_global' | translate }}
            </mat-panel-title>
            <mat-panel-title *ngIf="locationId">
                {{ 'location_settings_view' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'theme' | translate }}</mat-label>
                <mat-select [formControl]="viewFields.theme">
                    <mat-option [value]="THEME_NONE">{{ 'none' | translate }}</mat-option>
                    <mat-option [value]="THEME_DARK">{{ 'theme_dark' | translate }}</mat-option>
                    <mat-option [value]="THEME_LIGHT">{{ 'theme_light' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div appFieldContainer>
            <app-action [text]="'save' | translate" (onAsyncExecute)="saveView($event)"></app-action>
        </div>
    </mat-expansion-panel>
    <!-- ------------------------------------------------------------------ -->
    <!-- Locale -->
    <!-- ------------------------------------------------------------------ -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title *ngIf="businessId">
                {{ 'location_settings_locale_global' | translate }}
            </mat-panel-title>
            <mat-panel-title *ngIf="locationId">
                {{ 'location_settings_locale' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'language' | translate }}</mat-label>
                <mat-select [formControl]="localeFields.language">
                    <mat-option [value]="LANGUAGE_NONE">{{ 'none' | translate }}</mat-option>
                    <mat-option [value]="LANGUAGE_ENGLISH">{{ 'language_english' | translate }}</mat-option>
                    <mat-option [value]="LANGUAGE_SPANISH">{{ 'language_spanish' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div appFieldContainer>
            <app-action [text]="'save' | translate" (onAsyncExecute)="saveLocale($event)"></app-action>
        </div>
    </mat-expansion-panel>
    <!-- ------------------------------------------------------------------ -->
    <!-- Business Hours -->
    <!-- ------------------------------------------------------------------ -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title *ngIf="businessId">
                {{ 'location_settings_business_hours_global' | translate }}
            </mat-panel-title>
            <mat-panel-title *ngIf="locationId">
                {{ 'location_settings_business_hours' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <h4>{{ 'week_day_sunday' | translate }}</h4>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'opens_at' | translate }}</mat-label>
                <input matInput placeholder="9:00" [formControl]="businessHoursFields.sundayFrom">
                <mat-error *ngIf="businessHoursFields.sundayFrom.invalid">{{formService.getFieldErrorMessage(businessHoursFields.sundayFrom)}}</mat-error>
            </mat-form-field>
            <mat-form-field appField>
                <mat-label>{{ 'closes_at' | translate }}</mat-label>
                <input matInput placeholder="21:00" [formControl]="businessHoursFields.sundayTo">
                <mat-error *ngIf="businessHoursFields.sundayTo.invalid">{{formService.getFieldErrorMessage(businessHoursFields.sundayTo)}}</mat-error>
            </mat-form-field>
        </div>
        <h4>{{ 'week_day_monday' | translate }}</h4>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'opens_at' | translate }}</mat-label>
                <input matInput placeholder="9:00" [formControl]="businessHoursFields.mondayFrom">
                <mat-error *ngIf="businessHoursFields.mondayFrom.invalid">{{formService.getFieldErrorMessage(businessHoursFields.mondayFrom)}}</mat-error>
            </mat-form-field>
            <mat-form-field appField>
                <mat-label>{{ 'closes_at' | translate }}</mat-label>
                <input matInput placeholder="21:00" [formControl]="businessHoursFields.mondayTo">
                <mat-error *ngIf="businessHoursFields.mondayTo.invalid">{{formService.getFieldErrorMessage(businessHoursFields.mondayTo)}}</mat-error>
            </mat-form-field>
        </div>
        <h4>{{ 'week_day_tuesday' | translate }}</h4>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'opens_at' | translate }}</mat-label>
                <input matInput placeholder="9:00" [formControl]="businessHoursFields.tuesdayFrom">
                <mat-error *ngIf="businessHoursFields.tuesdayFrom.invalid">{{formService.getFieldErrorMessage(businessHoursFields.tuesdayFrom)}}</mat-error>
            </mat-form-field>
            <mat-form-field appField>
                <mat-label>{{ 'closes_at' | translate }}</mat-label>
                <input matInput placeholder="21:00" [formControl]="businessHoursFields.tuesdayTo">
                <mat-error *ngIf="businessHoursFields.tuesdayTo.invalid">{{formService.getFieldErrorMessage(businessHoursFields.tuesdayTo)}}</mat-error>
            </mat-form-field>
        </div>
        <h4>{{ 'week_day_wednesday' | translate }}</h4>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'opens_at' | translate }}</mat-label>
                <input matInput placeholder="9:00" [formControl]="businessHoursFields.wednesdayFrom">
                <mat-error *ngIf="businessHoursFields.wednesdayFrom.invalid">{{formService.getFieldErrorMessage(businessHoursFields.wednesdayFrom)}}</mat-error>
            </mat-form-field>
            <mat-form-field appField>
                <mat-label>{{ 'closes_at' | translate }}</mat-label>
                <input matInput placeholder="21:00" [formControl]="businessHoursFields.wednesdayTo">
                <mat-error *ngIf="businessHoursFields.wednesdayTo.invalid">{{formService.getFieldErrorMessage(businessHoursFields.wednesdayTo)}}</mat-error>
            </mat-form-field>
        </div>
        <h4>{{ 'week_day_thursday' | translate }}</h4>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'opens_at' | translate }}</mat-label>
                <input matInput placeholder="9:00" [formControl]="businessHoursFields.thursdayFrom">
                <mat-error *ngIf="businessHoursFields.thursdayFrom.invalid">{{formService.getFieldErrorMessage(businessHoursFields.thursdayFrom)}}</mat-error>
            </mat-form-field>
            <mat-form-field appField>
                <mat-label>{{ 'closes_at' | translate }}</mat-label>
                <input matInput placeholder="21:00" [formControl]="businessHoursFields.thursdayTo">
                <mat-error *ngIf="businessHoursFields.thursdayTo.invalid">{{formService.getFieldErrorMessage(businessHoursFields.thursdayTo)}}</mat-error>
            </mat-form-field>
        </div>
        <h4>{{ 'week_day_friday' | translate }}</h4>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'opens_at' | translate }}</mat-label>
                <input matInput placeholder="9:00" [formControl]="businessHoursFields.fridayFrom">
                <mat-error *ngIf="businessHoursFields.fridayFrom.invalid">{{formService.getFieldErrorMessage(businessHoursFields.fridayFrom)}}</mat-error>
            </mat-form-field>
            <mat-form-field appField>
                <mat-label>{{ 'closes_at' | translate }}</mat-label>
                <input matInput placeholder="21:00" [formControl]="businessHoursFields.fridayTo">
                <mat-error *ngIf="businessHoursFields.fridayTo.invalid">{{formService.getFieldErrorMessage(businessHoursFields.fridayTo)}}</mat-error>
            </mat-form-field>
        </div>
        <h4>{{ 'week_day_saturday' | translate }}</h4>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'opens_at' | translate }}</mat-label>
                <input matInput placeholder="9:00" [formControl]="businessHoursFields.saturdayFrom">
                <mat-error *ngIf="businessHoursFields.saturdayFrom.invalid">{{formService.getFieldErrorMessage(businessHoursFields.saturdayFrom)}}</mat-error>
            </mat-form-field>
            <mat-form-field appField>
                <mat-label>{{ 'closes_at' | translate }}</mat-label>
                <input matInput placeholder="21:00" [formControl]="businessHoursFields.saturdayTo">
                <mat-error *ngIf="businessHoursFields.saturdayTo.invalid">{{formService.getFieldErrorMessage(businessHoursFields.saturdayTo)}}</mat-error>
            </mat-form-field>
        </div>
        <div appFieldContainer>
            <app-action
                [text]="'save' | translate"
                [disabled]="!businessHoursFields.isBusinessHoursValid"
                (onAsyncExecute)="saveBusinessHours($event)">
            </app-action>
        </div>
    </mat-expansion-panel>
    <!-- ------------------------------------------------------------------ -->
    <!-- Taxes -->
    <!-- ------------------------------------------------------------------ -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title *ngIf="businessId">
                {{ 'location_settings_taxes_global' | translate }}
            </mat-panel-title>
            <mat-panel-title *ngIf="locationId">
                {{ 'location_settings_taxes' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'sales_tax' | translate }}</mat-label>
                <input matInput type="number" placeholder="0.0785" [formControl]="taxesConfigFields.salesTax">
                <mat-error *ngIf="taxesConfigFields.salesTax.invalid">{{formService.getFieldErrorMessage(taxesConfigFields.salesTax)}}</mat-error>
            </mat-form-field>
        </div>
        <div>
            {{ 'sales_tax_type_unalterable_description' | translate }}
        </div>
        <div>
            {{ 'sales_tax_type_fallback_description' | translate }}
        </div>
        <div appFieldContainer>
            <mat-radio-group [formControl]="taxesConfigFields.taxesType">
                <mat-radio-button [value]="TAX_TYPE_UNALTERABLE">{{ 'sales_tax_type_unalterable' | translate }}</mat-radio-button>
                <mat-radio-button [value]="TAX_TYPE_FALLBACK">{{ 'sales_tax_type_fallback' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div appFieldContainer>
            <app-action [text]="'save' | translate"
                [disabled]="taxesConfigFields.salesTax.invalid || !this.taxesConfigFields.isSalesTaxValid"
                (onAsyncExecute)="saveTaxes($event)">
            </app-action>
        </div>
    </mat-expansion-panel>
    <!-- ------------------------------------------------------------------ -->
    <!-- Ordering -->
    <!-- ------------------------------------------------------------------ -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title *ngIf="businessId">
                {{ 'location_settings_ordering_global' | translate }}
            </mat-panel-title>
            <mat-panel-title *ngIf="locationId">
                {{ 'location_settings_ordering' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div appFieldContainer>
            <mat-form-field appField>
                <mat-label>{{ 'order_preparation_diration_minutes' | translate }}</mat-label>
                <input matInput type="number" min="10" max="45" placeholder="15" [formControl]="orderingFields.orderPreparationDurationMinutes">
                <mat-error *ngIf="orderingFields.orderPreparationDurationMinutes.invalid">{{formService.getFieldErrorMessage(orderingFields.orderPreparationDurationMinutes)}}</mat-error>
            </mat-form-field>
        </div>
        <div appFieldContainer>
            <app-action [text]="'save' | translate" (onAsyncExecute)="saveOrdering($event)"></app-action>
        </div>
    </mat-expansion-panel>
    <!-- ------------------------------------------------------------------ -->
    <!-- Delivery -->
    <!-- ------------------------------------------------------------------ -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title *ngIf="businessId">
                {{ 'location_settings_delivery_global' | translate }}
            </mat-panel-title>
            <mat-panel-title *ngIf="locationId">
                {{ 'location_settings_delivery' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div appFieldContainer>
            <mat-radio-group [formControl]="deliveryConfigFields.deliveryType">
                <mat-radio-button [value]="NO_DELIVERY">{{ 'none' | translate }}</mat-radio-button>
                <mat-radio-button [value]="SELF_DELIVERY">{{ 'self_delivery' | translate }}</mat-radio-button>
                <mat-radio-button [value]="DOORDASH_DELIVERY">Doordash</mat-radio-button>
            </mat-radio-group>
        </div>
        <div>
            <app-heading>{{ 'delivery_self_config' | translate }}</app-heading>
        </div>
        <div>
            <p>
                {{ 'delivery_config_summary_1' | translate }}
            </p>
            <p>
                {{ 'delivery_config_summary_2' | translate }}
            </p>
            <p>
                {{ 'delivery_config_summary_3' | translate }}
                <br>
                <span class="math">{{ 'delivery_config_summary_4' | translate }}</span>
            </p>
            <br>
        </div>
        <div appFieldContainer>
            <app-money-picker
                label="{{ 'delivery_base_fee' | translate }}"
                [required]="true"
                [disabled]="deliveryConfigFields.isSelfConfigurationDisabled"
                [currencies]="CURRENCIES"
                [value]="deliveryConfigFields.baseFeeInitialValue"
                (onValueChange)="onBaseFeeChange($event)">
            </app-money-picker>
        </div>
        <div appFieldContainer>
            <app-quantity-picker
                label="{{ 'delivery_base_distance' | translate }}"
                [required]="true"
                [disabled]="deliveryConfigFields.isSelfConfigurationDisabled"
                [units]="DISTANCE_UNITS_NAMES"
                [value]="deliveryConfigFields.baseDistanceInitialValue"
                (onValueChange)="onBaseDistanceChange($event)">
            </app-quantity-picker>
        </div>
        <div appFieldContainer>
            <app-money-picker
                label="{{ 'delivery_fee_per_distance_unit' | translate }}"
                [required]="true"
                [disabled]="deliveryConfigFields.isSelfConfigurationDisabled"
                [currencies]="CURRENCIES"
                [value]="deliveryConfigFields.feePerDistanceUnitInitialValue"
                (onValueChange)="onFeePerDistanceUnitChange($event)">
            </app-money-picker>
        </div>
        <div appFieldContainer>
            <app-quantity-picker
                label="{{ 'delivery_max_distrance' | translate }}"
                [required]="true"
                [disabled]="deliveryConfigFields.isSelfConfigurationDisabled"
                [units]="DISTANCE_UNITS_NAMES"
                [value]="deliveryConfigFields.maxDistanceInitialValue"
                (onValueChange)="onMaxDistanceChange($event)">
            </app-quantity-picker>
        </div>
        <div appFieldContainer>
            <app-action [text]="'save' | translate"
                [disabled]="!deliveryConfigFields.isSelfConfigurationValid"
                (onAsyncExecute)="saveDelivery($event)">
            </app-action>
        </div>
    </mat-expansion-panel>
    <!-- ------------------------------------------------------------------ -->
    <!-- Printers -->
    <!-- ------------------------------------------------------------------ -->
    <!-- Does not apply for businesses -->
    <mat-expansion-panel *ngIf="locationId">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'location_settings_printers' | translate }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        TODO
    </mat-expansion-panel>
</mat-accordion>
