import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { BusinessHeaderComponent } from '../../components/business-header/business-header.component';
import { CampaignsComponent } from '../../components/campaigns/campaigns.component';
import { LocationHeaderComponent } from '../../components/location-header/location-header.component';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus, BusinessOrLocationId } from '../../util/util';
import { BackendService } from '../../services/backend.service';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { MenuEditorComponent } from '../../components/menu/menu-editor/menu-editor.component';
import { DeviceService } from 'src/app/general/services/device.service';
import { HeadingComponent } from 'src/app/general/components/heading/heading.component';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-edit-menu-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    CampaignsComponent,
    BusinessHeaderComponent,
    LocationHeaderComponent,
    ActionComponent,
    TranslateModule,
    LoadableContentComponent,
    MenuEditorComponent,
    HeadingComponent
  ],
  templateUrl: './edit-menu-page.component.html',
  styleUrls: ['./edit-menu-page.component.css']
})
export class EditMenuPageComponent implements OnInit {

  businessId: string;
  locationId: string;

  menuSpec: proto.waiternow.common.StructuredMenuSpecProto;
  draftGeneratedFromParentBusiness: boolean

  menuLoadingController: LoadingController;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute,
      private backendService: BackendService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private toastService: ToastService,
      public navigationService: NavigationService,
      public deviceService: DeviceService) {
    this.businessId = '';
    this.locationId = '';
    this.menuSpec = new proto.waiternow.common.StructuredMenuSpecProto();
    this.draftGeneratedFromParentBusiness = false;
    this.menuLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.businessId = Util.safeString(pathParams.get('businessId'));
    this.locationId = Util.safeString(pathParams.get('locationId'));
    this.loadMenuSpec();
  }

  public loadMenuSpec(): void {
    this.menuLoadingController.onLoadingStarted(LoadingMessage.empty());
    this.backendService.getDraftOfStructuredMenu(
      new BusinessOrLocationId(this.businessId, this.locationId),
      /* onSuccess= */ response => {
        if (response?.menu) {
          this.menuSpec = new proto.waiternow.common.StructuredMenuSpecProto(response.menu);
          this.draftGeneratedFromParentBusiness = response.draftGeneratedFromParentBusiness;
        }
        this.menuLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.menuLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_menu'));
      }
    );
  }

  public saveDraft(): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.backendService.saveDraftOfStructuredMenu(
      new BusinessOrLocationId(this.businessId, this.locationId),
      this.menuSpec,
      /* onSuccess= */ () => {
        closeProgressDialog();
        this.translateService.get('saved').subscribe(text => this.toastService.success(text));
      },
      /* onError */ error => {
        closeProgressDialog();
        this.translateService.get('error_saving_menu_draft').subscribe(text => this.toastService.error(text));
      }
    );
  }

  public deleteDraft(): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_delete_menu_draft').subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.removeDraftOfStructuredMenu(
            new BusinessOrLocationId(this.businessId, this.locationId),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('deleted').subscribe(text => this.toastService.success(text));
              this.loadMenuSpec();
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_deleting_menu_draft').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }

  public publishMenu(): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_publish_menu').subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.publishStructuredMenu(
            new BusinessOrLocationId(this.businessId, this.locationId),
            this.menuSpec,
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('published').subscribe(text => this.toastService.success(text));
              this.loadMenuSpec();
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_publishing_menu').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }

  public deleteMenu(): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_delete_menu').subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.removeStructuredMenu(
            new BusinessOrLocationId(this.businessId, this.locationId),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('deleted').subscribe(text => this.toastService.success(text));
              this.loadMenuSpec();
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_deleting_menu').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }
}
