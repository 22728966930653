import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { ActivatedRoute } from '@angular/router';
import { Runnable } from 'src/app/general/interfaces/functions';
import { EnvironmentUtil } from '../util/environment-util';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
      private router: Router){
  }

  // See com.waiternow.app.web.waiternow.main.frontend.support.ServerMain (RedirectController).
  // RedirectController redirects all paths to the root and includes the original path as query
  // parameter "original_path".
  // When you try to access "/foo/bar" directly, the browser sends HTTP GET request to such
  // URL, causing a page not found error. When you try to access this URL via Angular link
  // clicking, Angular is intercepting this action and does the redirect. Thus, every request
  // has to be redirected to the index file that bootstraps AngularJS application.
  public handleRedirect(activatedRoute: ActivatedRoute, onNotRedirected: Runnable = () => {}): void {
    RouteUtil.getQueryParams(activatedRoute, params => {
      if (params['original_path']) {
        this.router.navigate([params['original_path']], {})
      } else {
        onNotRedirected();
      }
    });
  }

  public openInNewTab(url: string): void {
    const windowProxy = window.open(url, '_blank');
    if (windowProxy) {
      windowProxy.focus();
    }
  }

  public goToMenuEditor(): void {
    this.openInNewTab(
      EnvironmentUtil.isProd()
       ? 'https://docs.google.com/spreadsheets/d/1-xZr10SxU9anj89XgszZBSazttmSY25JizBhQWOWNdw/template/preview'
       : 'https://docs.google.com/spreadsheets/d/1JUMXgwhdX-1z2yxj_NUQ4OnOXLc1txA3f7vCaFRDQJE/template/preview'
    );
  }

  public goToConsumer(pointOfServiceId: string): void {
    this.openInNewTab(
      EnvironmentUtil.isProd()
       ? 'https://scan.waiternow.com/pos/' + pointOfServiceId
       : 'https://scan.waiternow.dev/pos/' + pointOfServiceId
    );
  }

  public goToHomePage(): void {
    this.router.navigate([''], {});
  }

  public goToSignInPage(): void {
    this.router.navigate(['signin'], {});
  }

  public goToMetricsPage(): void {
    this.router.navigate(['metrics'], {});
  }

  public goToSuperuserPage(): void {
    this.router.navigate(['superuser'], {});
  }

  public goToDebugPage(): void {
    this.router.navigate(['debug'], {});
  }

  public goToUnackedAndPaidOrdersPage(): void {
    this.router.navigate(['unacked_and_paid_orders'], {});
  }

  public goToDisputedOrdersPage(): void {
    this.router.navigate(['disputed_orders'], {});
  }

  public goToCreateUserPage(): void {
    this.router.navigate(['create_user'], {});
  }

  public goToUserPage(userIdOrEmail: string): void {
    this.router.navigate(['/user', userIdOrEmail]);
  }

  public goToUserBusinessesPage(userId: string): void {
    this.router.navigate(['/user', userId, 'businesses']);
  }

  public goToUserAddBusinessesPage(userId: string): void {
    this.router.navigate(['/user', userId, 'businesses', 'add']);
  }

  public goToEditUserPage(userId: string): void {
    this.router.navigate(['/user', userId, 'edit']);
  }

  public goToBusinessPage(businessId: string): void {
    this.router.navigate(['/business', businessId]);
  }

  public goToEditBusinessPage(businessId: string): void {
    this.router.navigate(['/business', businessId, 'edit']);
  }

  public goToBusinessUsersPage(businessId: string): void {
    this.router.navigate(['/business', businessId, 'users']);
  }

  public goToBusinessLocationsPage(businessId: string): void {
    this.router.navigate(['/business', businessId, 'locations']);
  }

  public goToAddLocationPage(businessId: string): void {
    this.router.navigate(['/business', businessId, 'locations', 'add']);
  }

  public goToLocationPage(locationId: string): void {
    this.router.navigate(['/location', locationId]);
  }

  public goToEditLocationPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'edit']);
  }

  public goToLocationDevicesPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'devices']);
  }

  public goToLocationMetricsPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'metrics']);
  }

  public goToLocationOrdersPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'orders']);
  }

  public goToLocationPointsOfServicePage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'points_of_service']);
  }

  public goToAddPointOfServicePage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'points_of_service', 'add']);
  }

  public goToLocationRefundedOrdersPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'refunded_orders']);
  }

  public goToLocationDisputedOrdersPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'disputed_orders']);
  }

  public goToLocationQrCodesPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'qr_codes']);
  }

  public goToPointOfServicePage(pointOfServiceId: string): void {
    this.router.navigate(['/point_of_service', pointOfServiceId]);
  }

  public goToEditPointOfServicePage(pointOfServiceId: string): void {
    this.router.navigate(['/point_of_service', pointOfServiceId, 'edit']);
  }

  public goToCampaignPage(campaignId: string): void {
    this.router.navigate(['/campaign', campaignId]);
  }

  public goToEditCampaignPage(campaignId: string): void {
    this.router.navigate(['/campaign', campaignId, 'edit']);
  }

  public goToBusinessCampaignsPage(businessId: string): void {
    this.router.navigate(['/business', businessId, 'campaigns']);
  }

  public goToAddBusinessCampaignPage(businessId: string): void {
    this.router.navigate(['/business', businessId, 'campaigns', 'add']);
  }

  public goToLocationCampaignsPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'campaigns']);
  }

  public goToAddLocationCampaignPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'campaigns', 'add']);
  }

  public goToOrderPage(orderId: string): void {
    this.router.navigate(['/order', orderId]);
  }

  public goToEditOrderPage(orderId: string): void {
    this.router.navigate(['/order', orderId, 'edit']);
  }

  public goToDeviceOrdersPage(): void {
    this.router.navigate(['device_orders'], {});
  }

  public goToEditBusinessMenuPage(businessId: string): void {
    this.router.navigate(['/business', businessId, 'menu', 'edit']);
  }

  public goToEditLocationMenuPage(locationId: string): void {
    this.router.navigate(['/location', locationId, 'menu', 'edit']);
  }
}
