import { Injectable } from '@angular/core';
import { ProtoUtil } from '../util/proto-util';
import { AppError } from '../../general/util/error';
import { StorageService } from './storage.service';
import { NavigationService } from './navigation.service';
import { AuthenticationStatus } from '../util/util';
import { Util } from 'src/app/general/util/util';
import { TimeUtil } from 'src/app/general/util/time-util';
import { Param } from 'src/app/general/interfaces/param';
import * as proto from 'src/proto/compiled-protos';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  authToken: string;
  validUntil: Date;
  isAdminUser: boolean;

  constructor(
      private storageService: StorageService,
      private navigationService: NavigationService) {
    this.authToken = Util.safeString(this.storageService.getAuthTokenStore().get());
    const storedValidUntil = this.storageService.getAuthTokenValidUntilStore().get();
    if (storedValidUntil) {
      this.validUntil = storedValidUntil;
    } else {
      this.validUntil = new Date();
    }
    this.isAdminUser = this.storageService.getIsAdminUserStore().get() == true;
  }

  public setAuthToken(
      value: string, since: proto.google.protobuf.ITimestamp | null | undefined,
      expiration: proto.google.protobuf.IDuration | null | undefined): void {
    this.authToken = value;
    if (since && expiration) {
      this.validUntil = TimeUtil.plusMillis(
        TimeUtil.createDateFromMillis(ProtoUtil.timestampProtoToMillis(since)),
        ProtoUtil.durationProtoToMillis(expiration));
    } else {
      // Uses 10 hours as default
      this.validUntil = TimeUtil.plusMillis(new Date(), 10 * 60 * 1000);
    }
    this.storageService.getAuthTokenStore().set(this.authToken);
    this.storageService.getAuthTokenValidUntilStore().set(this.validUntil);
  }

  public getAuthToken(): string {
    return this.authToken;
  }

  /**
   * Method to use with the action component to add the auth token form param.
   */
  public getAuthTokenFormParam(): Param {
    return { name: 'Auth-Token', value: this.authToken };
  }

  public setIsAdminUser(isAdminUser: boolean): void {
    this.isAdminUser = isAdminUser;
    this.storageService.getIsAdminUserStore().set(isAdminUser);
  }

  public getIsAdminUser(): boolean {
    return this.isAdminUser;
  }

  public logout() {
    this.authToken = '';
    this.storageService.getAuthTokenStore().clear();
    this.storageService.getAuthTokenValidUntilStore().clear;
    this.storageService.getIsAdminUserStore().clear();
  }

  /**
   * Checks whether the user is authenticated, if not it gets redirected to the sign in page.
   *
   * @returns true if the user is authenticated, false if the page will be redirected to the sign in page
   */
  public enforceAuthentication(): AuthenticationStatus {
    if (!this.authToken || this.validUntil < TimeUtil.now()) {
      this.logout();
      this.navigationService.goToSignInPage();
      return AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE;
    }
    return AuthenticationStatus.USER_ALREADY_AUTHENTICATED;
  }

  /**
   * Checks whether the error is an authentication error and redirects to the sign in page.
   *
   * @param error error to check
   * @returns true if the error was handled, false otherwise
   */
  public handleAuthenticationError(error: AppError): boolean {
    if (error.isAuthenticationError()) {
      this.navigationService.goToSignInPage();
      return true;
    }
    return false;
  }
}
