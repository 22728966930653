import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, TrackByFunction } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackendService } from 'src/app/specific/services/backend.service';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { Formatter } from 'src/app/specific/util/formatter';
import { DeviceService } from 'src/app/general/services/device.service';
import { MatCardModule } from '@angular/material/card';
import { SubtitleComponent } from 'src/app/general/components/subtitle/subtitle.component';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProtoUtil } from 'src/app/specific/util/proto-util';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DialogService } from 'src/app/general/services/dialog.service';
import { CategoryEditorDialogService } from '../category-editor-dialog/category-editor-dialog.service';
import { Util } from 'src/app/general/util/util';
import { FileSelectorComponent, FileSelectorOutput } from 'src/app/general/components/file-selector/file-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { BusinessOrLocationId } from 'src/app/specific/util/util';
import { ToastService } from 'src/app/general/services/toast.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuItemEditorDialogService } from '../menu-item-editor-dialog/menu-item-editor-dialog.service';
import { ItemEditorDialogService } from '../item-editor-dialog/item-editor-dialog.service';
import { SelectItemDialogService } from '../select-item-dialog/select-item-dialog.service';
import { EditorCategory, CategoryEditorDialogData, ItemEditorDialogData, ItemSelectionEditorDialogData, MenuItemEditorDialogData, SelectItemDialogData, SelectScheduleDialogData, SelectItemSelectionDialogData } from '../util/interfaces';
import { MenuEditorUtil } from '../util/menu-editor-util';
import { ItemSelectionReusedWarningComponent } from '../item-selection-reused-warning/item-selection-reused-warning.component';
import { ItemReusedWarningComponent } from '../item-reused-warning/item-reused-warning.component';
import { RightAlignedCompactContentComponent } from 'src/app/general/components/right-aligned-compact-content/right-aligned-compact-content.component';
import { SelectScheduleDialogService } from '../select-schedule-dialog/select-schedule-dialog.service';
import { ItemSelectionEditorDialogService } from '../item-selection-editor-dialog/item-selection-editor-dialog.service';
import { SelectItemSelectionDialogService } from '../select-item-selection-dialog/select-item-selection-dialog.service';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-menu-editor [businesId]="businessId" [menuSpec]="menuSpec"></app-menu-editor>
// <app-menu-editor [locationId]="locationId" [menuSpec]="menuSpec"></app-menu-editor>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-menu-editor',
  standalone: true,
  imports: [
    CommonModule,
    SubtitleComponent,
    MatCardModule,
    ActionComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FieldContainerDirective,
    FieldDirective,
    MatCheckboxModule,
    MatRadioModule,
    FileSelectorComponent,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    ItemSelectionReusedWarningComponent,
    ItemReusedWarningComponent,
    RightAlignedCompactContentComponent
  ],
  templateUrl: './menu-editor.component.html',
  styleUrls: ['./menu-editor.component.css']
})
export class MenuEditorComponent implements OnInit, OnChanges {

  /*---------------------------------------------------------------------------
  Notes about implementation:
  - We have two types of menu: a specification and the denormalized menu.
  - The menu specification defines relations so data is reused, the denormalized
    version is optimized for rendering.
  - The editor renders the menu using the denormalized version, so updates
    reflect the final result.
  - We only need to update the specification and then we can ask the backend to
    denormalize it. However, the menu editor updates both the specification and
    the denormalized menu, this to provide a better user experience. Otherwise
    for every little change we would ahow a progress bar will the menu is being
    denormalized.
  - The denormalized categories order is the order defined by thje menuSpec.categories
  - The denormalized menu entries order within each category the menu entry belongs
    to is defined by the menuSpec.menuItems order and menuSpec.combos order. Thus,
    if a menu item is resinserted in a different position in the menu spec, it might
    affect all categories the menu item belongs to.
  ---------------------------------------------------------------------------*/

  // Business id and location id are just used to add images to the structured menu.
  @Input() businessId?: string;
  @Input() locationId?: string;

  @Input() menuSpec!: proto.waiternow.common.StructuredMenuSpecProto;
  unfilteredMenu: proto.waiternow.common.IStructuredMenuProto;
  menu: proto.waiternow.common.IStructuredMenuProto;
  selectedMenuEntry?: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto;

  searchFormControl: FormControl;

  // This is used to avoid reloading an image if its id has not changed. Otherwise everytime the component is
  // updated all images are reloaded (For example every time a buttons is clicked, all images were reloaded).
  // https://angular.dev/api/common/NgForOf
  // https://angular.dev/api/common/NgFor
  public trackCategoryChanges: TrackByFunction<proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto> = (index, category) =>  this.categoryHashById.get(Util.safeString(category.id));
  public trackMenuEntryChanges: TrackByFunction<proto.waiternow.common.StructuredMenuProto.IMenuEntryProto> = (index, menuEntry) =>  this.menuEntryHashById.get(this.getMenuEntryId(menuEntry));
  public trackMenuEntryGroupChanges: TrackByFunction<Array<proto.waiternow.common.StructuredMenuProto.IMenuEntryProto>> = (index, menuEntryGroup) =>  {
    let hash = '';
    for (const menuEntry of menuEntryGroup) {
      hash += this.menuEntryHashById.get(this.getMenuEntryId(menuEntry));
    }
    return hash;
  }

  // private menuHash: string = '';
  private categoryHashById = new Map<string, string>();
  private menuEntryHashById = new Map<string, string>();

  menuItemsPerRow: number = 0;
  menuItemColumnWidth: string = '';

  constructor(
      private backendService: BackendService,
      private localizationService: LocalizationService,
      private translateService: TranslateService,
      private dialogService: DialogService,
      private toastService: ToastService,
      private categoryEditorDialogService: CategoryEditorDialogService,
      private menuItemEditorDialogService: MenuItemEditorDialogService,
      private selectItemSelectionDialogService: SelectItemSelectionDialogService,
      private itemSelectionEditorDialogService: ItemSelectionEditorDialogService,
      private itemEditorDialogService: ItemEditorDialogService,
      private selectItemDialogService: SelectItemDialogService,
      private selectScheduleDialogService: SelectScheduleDialogService,
      public deviceService: DeviceService) {
    this.unfilteredMenu = new proto.waiternow.common.StructuredMenuProto();
    this.menu = new proto.waiternow.common.StructuredMenuProto();
    this.searchFormControl = new FormControl();
    this.updateMenuItemsPerRow();
  }

  ngOnInit(): void {
    this.denormalizeMenu();
    this.searchFormControl.valueChanges.subscribe(() => {
      this.filterMenu();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('menuSpec', changes)) {
      this.denormalizeMenu();
      this.selectedMenuEntry = undefined;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateMenuItemsPerRow();
  }

  private updateMenuItemsPerRow(): void {
    if (this.deviceService.isMobile()) {
      this.menuItemsPerRow = 1;
    } else {
      if (window.innerWidth > 0) {
        const maxMenuItemWidthApproximation = 600;
        this.menuItemsPerRow = Math.ceil(window.innerWidth / maxMenuItemWidthApproximation);
      } else {
        this.menuItemsPerRow = 3;
      }
    }
    this.menuItemColumnWidth = Math.floor(100 / this.menuItemsPerRow) + '%';
  }

  private denormalizeMenu(): void {
    this.backendService.denormalizeStructuredMenu(
      this.menuSpec,
      /* onSuccess= */ denormalizedMenu => {
        if (denormalizedMenu) {
          this.rehashMenu();
          this.unfilteredMenu = new proto.waiternow.common.StructuredMenuProto(denormalizedMenu);
          this.filterMenu();
        }
      },
      /* onError= */ error => {
        // TODO:
      }
    );
  }

  private filterMenu(): void {
    if (!this.searchFormControl.value) {
      this.menu = this.unfilteredMenu;
      this.menuChanged();
      return;
    }

    const searchText = this.searchFormControl.value.toLowerCase();

    const filteredMenu = new proto.waiternow.common.StructuredMenuProto();

    // TODO: uncategorized menu entries and categories level 2 and 3 are not considered

    filteredMenu.categories = [];
    for (const unfilteredCategory of this.unfilteredMenu.categories || []) {
      if (this.getNonEmptyText(unfilteredCategory.name).toLowerCase().indexOf(searchText) >= 0) {
        filteredMenu.categories.push(unfilteredCategory);
      } else {
        const filteredCategory = new proto.waiternow.common.StructuredMenuProto.CategoryLevel1Proto(unfilteredCategory);
        filteredCategory.menuEntries = [];
        if (unfilteredCategory.menuEntries) {
          for (const menuEntry of unfilteredCategory.menuEntries) {
            if (this.getMenuEntryName(menuEntry).toLowerCase().indexOf(searchText) >= 0) {
              filteredCategory.menuEntries.push(menuEntry);
            }
            else if (this.getMenuEntryDescription(menuEntry).toLowerCase().indexOf(searchText) >= 0) {
              filteredCategory.menuEntries.push(menuEntry);
            }
          }
        }
        if (filteredCategory.menuEntries.length > 0) {
          filteredMenu.categories.push(filteredCategory);
        }
      }
    }

    this.menuChanged();
    this.menu = filteredMenu;
  }

  private rehashMenu(): void {
    // this.menuHash = ProtoUtil.protoToString(this.menuSpec);
    for (const category of this.unfilteredMenu.categories || []) {
      this.categoryHashById.set(Util.safeString(category.id), ProtoUtil.protoToString(category));
      for (const menuEntry of category.menuEntries || []) {
        this.menuEntryHashById.set(this.getMenuEntryId(menuEntry), ProtoUtil.protoToString(menuEntry));
      }
    }
  }

  private menuChanged(): void {
    this.rehashMenu();
  }

  public getNonEmptyText(textProto: proto.waiternow.common.ITextProto | null | undefined): string {
    return MenuEditorUtil.getNonEmptyText(textProto, this.localizationService);
  }

  public getMenuEntryId(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): string {
    return MenuEditorUtil.getMenuEntryId(menuEntry);
  }

  public getMenuEntryName(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): string {
    if (menuEntry?.menuItem?.name) {
      return this.getNonEmptyText(menuEntry.menuItem.name);
    } else if (menuEntry?.combo) {
      return this.getNonEmptyText(menuEntry.combo.name);
    }
    return '';
  }

  public getMenuEntryImageId(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): string {
    if (menuEntry?.menuItem?.image?.imageId) {
      return menuEntry.menuItem.image.imageId;
    } else if (menuEntry?.combo?.image?.imageId) {
      return menuEntry.combo.image.imageId;
    }
    return '';
  }

  public hasMenuEntryImage(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): boolean {
    if (menuEntry?.menuItem?.image?.imageUrl) {
      return true;
    } else if (menuEntry?.combo?.image?.imageUrl) {
      return true;
    }
    return false;
  }

  public getMenuEntryImageUrl(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): string {
    if (menuEntry?.menuItem?.image?.imageUrl) {
      return menuEntry.menuItem.image.imageUrl;
    } else if (menuEntry?.combo?.image?.imageUrl) {
      return menuEntry.combo.image.imageUrl;
    }
    return '../../assets/img/no-image-placeholder.jpg';
  }

  public getMenuEntryPrice(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): string {
    if (menuEntry?.menuItem) {
      return Formatter.formatMoney(menuEntry?.menuItem?.price);
    } else if (menuEntry?.combo) {
      return Formatter.formatMoney(menuEntry?.menuItem?.price);
    }
    return '';
  }

  public geItemPrice(item: proto.waiternow.common.StructuredMenuProto.IItemProto): string {
    return Formatter.formatMoney(item.price);
  }

  public getMenuEntryDescription(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): string {
    if (menuEntry?.menuItem?.description) {
      return this.getNonEmptyText(menuEntry?.menuItem?.description);
    } else if (menuEntry?.combo?.description) {
      return this.getNonEmptyText(menuEntry?.combo?.description);
    }
    return '';
  }

  public containsAlcohol(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): boolean {
    if (menuEntry?.menuItem && menuEntry?.menuItem?.containsAlcohol) {
      return true;
    } else if (menuEntry?.combo) {
      for (const menyItem of menuEntry?.combo?.menuItems || []) {
        if (menyItem.containsAlcohol) {
          return true;
        }
      }
    }
    return false;
  }

  public getMenuEntryItemSelections(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): Array<proto.waiternow.common.StructuredMenuProto.IItemSelectionProto> {
    if (menuEntry?.menuItem?.itemSelections) {
      return menuEntry?.menuItem?.itemSelections;
    } else if(menuEntry?.combo?.itemSelections) {
      return menuEntry?.combo?.itemSelections;
    }
    return [];
  }

  public isSingleSelection(itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): boolean {
    return itemSelection.selectionType == proto.waiternow.common.SelectionType.SINGLE;
  }

  public hasMaxSelections(itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): boolean {
    if (itemSelection.maxSelections) {
      return itemSelection.maxSelections > 0;
    }
    return false;
  }

  public getMenuEntryRemovableIngredients(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): Array<proto.waiternow.common.StructuredMenuProto.IItemProto> {
    return MenuEditorUtil.getMenuEntryRemovableIngredients(menuEntry);
  }

  public hasAvailabilitySchedule(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): boolean {
    if (menuEntry?.menuItem && menuEntry?.menuItem?.availabilitySchedule) {
      return true;
    } else if (menuEntry?.combo && menuEntry?.combo?.availabilitySchedule) {
      return true;
    }
    return false;
  }

  public getAvailabilitySchedule(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): proto.waiternow.common.IAvailabilityScheduleProto | undefined {
    if (menuEntry?.menuItem && menuEntry?.menuItem?.availabilitySchedule) {
      return menuEntry?.menuItem?.availabilitySchedule;
    } else if (menuEntry?.combo && menuEntry?.combo?.availabilitySchedule) {
      return menuEntry?.combo?.availabilitySchedule;
    }
    return undefined;
  }

  public getAvailabilityScheduleText(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): string {
    const availabilitySchedule = this.getAvailabilitySchedule(menuEntry);
    if (availabilitySchedule) {
      return Formatter.formatSchedule(
        availabilitySchedule.schedule,
        this.localizationService.isSpanish() ? proto.waiternow.common.Language.SPANISH : proto.waiternow.common.Language.ENGLISH);
    }
    return '';
  }

  public splitMenuItemsInGroups(
      category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto,
      groupSize: number)
      : Array<Array<proto.waiternow.common.StructuredMenuProto.IMenuEntryProto>> {
    if (!category.menuEntries) {
      return [];
    }
    const menuEntriesGroups: Array<Array<proto.waiternow.common.StructuredMenuProto.IMenuEntryProto>> = [];
    for (let i = 0; i < category.menuEntries.length; i += groupSize) {
      menuEntriesGroups.push(category.menuEntries.slice(i, i + groupSize));
    }
    return menuEntriesGroups;
  }

  public openMenuEntryDetails(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.selectedMenuEntry = menuEntry;
  }

  public closeMenuEntryDetails(): void {
    this.selectedMenuEntry = undefined;
  }

  public isMenuFiltered(): boolean {
    if (this.searchFormControl.value) {
      return true;
    }
    return false;
  }

  public isFirstCategory(category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): boolean {
    if (!this.unfilteredMenu.categories || this.unfilteredMenu.categories.length == 0) {
      return false;
    }
    return category.id == this.unfilteredMenu.categories[0].id;
  }

  public isLastCategory(category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): boolean {
    if (!this.unfilteredMenu.categories || this.unfilteredMenu.categories.length == 0) {
      return false;
    }
    return category.id == this.unfilteredMenu.categories[this.unfilteredMenu.categories.length - 1].id;
  }

  public isFirstMenuEntryInCategory(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): boolean {
    if (!category.menuEntries || category.menuEntries.length == 0) {
      return false;
    }
    return this.getMenuEntryId(menuEntry) == this.getMenuEntryId(category.menuEntries[0]);
  }

  public isLastMenuEntryInCategory(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): boolean {
    if (!category.menuEntries || category.menuEntries.length == 0) {
      return false;
    }
    return this.getMenuEntryId(menuEntry) == this.getMenuEntryId(category.menuEntries[category.menuEntries.length - 1]);
  }

  public isFirstItemSelectionInMenuEntry(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): boolean {
    const selections = this.getMenuEntryItemSelections(menuEntry);
    if (!selections || selections.length == 0) {
      return false;
    }
    return itemSelection.id == selections[0].id;
  }

  public isLastItemSelectionInMenuEntry(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): boolean {
    const selections = this.getMenuEntryItemSelections(menuEntry);
    if (!selections || selections.length == 0) {
      return false;
    }
    return itemSelection.id == selections[selections.length - 1].id;
  }

  public isFirstItemInItemSelection(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): boolean {
    if (!itemSelection.items || itemSelection.items.length == 0) {
      return false;
    }
    return item.id == itemSelection.items[0].id;
  }

  public isLastItemInItemSelection(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): boolean {
    if (!itemSelection.items || itemSelection.items.length == 0) {
      return false;
    }
    return item.id == itemSelection.items[itemSelection.items.length - 1].id;
  }

  public isFirstItemInRemovableIngredients(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): boolean {
    const removableIngredients = this.getMenuEntryRemovableIngredients(menuEntry);
    if (!removableIngredients || removableIngredients.length == 0) {
      return false;
    }
    return item.id == removableIngredients[0].id;
  }

  public isLastItemInRemovableIngredients(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): boolean {
    const removableIngredients = this.getMenuEntryRemovableIngredients(menuEntry);
    if (!removableIngredients || removableIngredients.length == 0) {
      return false;
    }
    return item.id == removableIngredients[removableIngredients.length - 1].id;
  }

  private generateId(idPrefix: string, currentIds: Array<string  | null | undefined>) : string {
    let count = 1;
    let id = idPrefix + (count++);
    while(Util.arrayContains(id, currentIds)) {
      id = idPrefix + (count++);
    }
    return id;
  }

  private generateCategoryId() : string {
    return this.generateId('category-id-', this.menuSpec.categories.map(category => category.id));
  }

  private generateItemId() : string {
    return this.generateId('item-id-', this.menuSpec.items.map(item => item.id));
  }

  private generateItemSelectionId() : string {
    return this.generateId('item-selection-id-', this.menuSpec.itemSelections.map(itemSelection => itemSelection.id));
  }

  private generateMenuItemId() : string {
    return this.generateId('menu-item-id-', this.menuSpec.menuItems.map(menuItem => menuItem.id));
  }

  private generateScheduleId() : string {
    return this.generateId('schedule-id-', this.menuSpec.schedules.map(schedule => schedule.id));
  }

  private visitMenuEntries(
      menuItemVisitor: Consumer<proto.waiternow.common.StructuredMenuProto.IMenuItemProto>,
      comboVisitor: Consumer<proto.waiternow.common.StructuredMenuProto.IComboProto>): void {

    for (const menuEntry of this.unfilteredMenu.uncategorizedMenuEntries || []) {
      if (menuEntry.menuItem) {
        menuItemVisitor(menuEntry.menuItem);
      } else if(menuEntry.combo) {
        comboVisitor(menuEntry.combo);
      }
    }

    for (const categoryLevel1 of this.unfilteredMenu.categories || []) {
      if (categoryLevel1.menuEntries) {
        for (const menuEntry of categoryLevel1.menuEntries) {
          if (menuEntry.menuItem) {
            menuItemVisitor(menuEntry.menuItem);
          } else if(menuEntry.combo) {
            comboVisitor(menuEntry.combo);
          }
        }
      }
      if (categoryLevel1.categories) {
        for (const categoryLevel2 of categoryLevel1.categories) {
          if (categoryLevel2.menuEntries) {
            for (const menuEntry of categoryLevel2.menuEntries) {
              if (menuEntry.menuItem) {
                menuItemVisitor(menuEntry.menuItem);
              } else if(menuEntry.combo) {
                comboVisitor(menuEntry.combo);
              }
            }
          }
          if (categoryLevel2.categories) {
            for (const categoryLevel3 of categoryLevel2.categories) {
              if (categoryLevel3.menuEntries) {
                for (const menuEntry of categoryLevel3.menuEntries) {
                  if (menuEntry.menuItem) {
                    menuItemVisitor(menuEntry.menuItem);
                  } else if(menuEntry.combo) {
                    comboVisitor(menuEntry.combo);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  private findDenormalizedMenuEntries(id: string): Array<proto.waiternow.common.StructuredMenuProto.IMenuEntryProto> {
    const result: Array<proto.waiternow.common.StructuredMenuProto.IMenuEntryProto> = [];

    for (const menuEntry of this.unfilteredMenu.uncategorizedMenuEntries || []) {
      if (this.getMenuEntryId(menuEntry) == id) {
        result.push(menuEntry);
      }
    }

    for (const categoryLevel1 of this.unfilteredMenu.categories || []) {
      if (categoryLevel1.menuEntries) {
        for (const menuEntry of categoryLevel1.menuEntries) {
          if (this.getMenuEntryId(menuEntry) == id) {
            result.push(menuEntry);
            break;
          }
        }
      }
      if (categoryLevel1.categories) {
        for (const categoryLevel2 of categoryLevel1.categories) {
          if (categoryLevel2.menuEntries) {
            for (const menuEntry of categoryLevel2.menuEntries) {
              if (this.getMenuEntryId(menuEntry) == id) {
                result.push(menuEntry);
                break;
              }
            }
          }
          if (categoryLevel2.categories) {
            for (const categoryLevel3 of categoryLevel2.categories) {
              if (categoryLevel3.menuEntries) {
                for (const menuEntry of categoryLevel3.menuEntries) {
                  if (this.getMenuEntryId(menuEntry) == id) {
                    result.push(menuEntry);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }

    return result;
  }

  public applyMenuEntryUpdate(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      menuItemSpecUpdater: Consumer<proto.waiternow.common.StructuredMenuSpecProto.MenuItemProto>,
      comboSpecUpdater: Consumer<proto.waiternow.common.StructuredMenuSpecProto.ComboProto>,
      menuItemUpdater: Consumer<proto.waiternow.common.StructuredMenuProto.MenuItemProto>,
      comboUpdater: Consumer<proto.waiternow.common.StructuredMenuProto.ComboProto>): void {
    // Updates menu spec
    if (menuEntry.menuItem) {
      for (const menuItemSpec of this.menuSpec.menuItems) {
        if (menuItemSpec.id == menuEntry.menuItem.id) {
          menuItemSpecUpdater(menuItemSpec as proto.waiternow.common.StructuredMenuSpecProto.MenuItemProto);
          break;
        }
      }
    } else if (menuEntry.combo) {
      for (const comboSpec of this.menuSpec.combos) {
        if (comboSpec.id == menuEntry.combo.id) {
          comboSpecUpdater(comboSpec as proto.waiternow.common.StructuredMenuSpecProto.ComboProto);
          break;
        }
      }
    }

    // Updates denormalized menu. Note that we don't directly update menuEntry becuase the same
    // menu entry may belong to multiple categories. So we update all instances.
    const menuEntries = this.findDenormalizedMenuEntries(this.getMenuEntryId(menuEntry));
    for (const denormalizedMenuEntry of menuEntries) {
      if (denormalizedMenuEntry.menuItem) {
        menuItemUpdater(denormalizedMenuEntry.menuItem as proto.waiternow.common.StructuredMenuProto.MenuItemProto);
      } else if (denormalizedMenuEntry.combo) {
        comboUpdater(denormalizedMenuEntry.combo as proto.waiternow.common.StructuredMenuProto.ComboProto);
      }
    }

    this.menuChanged();
  }

  public applyItemSelectionUpdate(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      itemSelectionSpecUpdater: Consumer<proto.waiternow.common.StructuredMenuSpecProto.IItemSelectionProto>,
      itemSelectionUpdater: Consumer<proto.waiternow.common.StructuredMenuProto.IItemSelectionProto>): void {
    // Updates menu spec
    for (const itemSlectionSpec of this.menuSpec.itemSelections) {
      if (itemSlectionSpec.id == itemSelection.id) {
        itemSelectionSpecUpdater(itemSlectionSpec as proto.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto);
        break;
      }
    }

    // Updates denormalized menu. Note that we don't directly update item becuase the same
    // item selection may belong to multiple menu items. So we update all instances.

    for (const menuEntry of this.unfilteredMenu.uncategorizedMenuEntries || []) {
      this.applyItemSelectionUpdateForMenuEntry(itemSelection, menuEntry, itemSelectionUpdater);
    }

    for (const categoryLevel1 of this.unfilteredMenu.categories || []) {
      if (categoryLevel1.menuEntries) {
        for (const menuEntry of categoryLevel1.menuEntries) {
          this.applyItemSelectionUpdateForMenuEntry(itemSelection, menuEntry, itemSelectionUpdater);
        }
      }
      if (categoryLevel1.categories) {
        for (const categoryLevel2 of categoryLevel1.categories) {
          if (categoryLevel2.menuEntries) {
            for (const menuEntry of categoryLevel2.menuEntries) {
              this.applyItemSelectionUpdateForMenuEntry(itemSelection, menuEntry, itemSelectionUpdater);
            }
          }
          if (categoryLevel2.categories) {
            for (const categoryLevel3 of categoryLevel2.categories) {
              if (categoryLevel3.menuEntries) {
                for (const menuEntry of categoryLevel3.menuEntries) {
                  this.applyItemSelectionUpdateForMenuEntry(itemSelection, menuEntry, itemSelectionUpdater);
                }
              }
            }
          }
        }
      }
    }

    this.menuChanged();
  }

  private applyItemSelectionUpdateForMenuEntry(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      itemSelectionpdater: Consumer<proto.waiternow.common.StructuredMenuProto.ItemSelectionProto>): void {
    for (const _itemSelection of this.getMenuEntryItemSelections(menuEntry)) {
      if (_itemSelection.id == itemSelection.id) {
        itemSelectionpdater(_itemSelection as proto.waiternow.common.StructuredMenuProto.ItemSelectionProto);
      }
    }
  }

  public applyItemUpdate(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      itemSpecUpdater: Consumer<proto.waiternow.common.StructuredMenuSpecProto.ItemProto>,
      itemUpdater: Consumer<proto.waiternow.common.StructuredMenuProto.ItemProto>): void {
    // Updates menu spec
    for (const itemSpec of this.menuSpec.items) {
      if (itemSpec.id == item.id) {
        itemSpecUpdater(itemSpec as proto.waiternow.common.StructuredMenuSpecProto.ItemProto);
        break;
      }
    }

    // Updates denormalized menu. Note that we don't directly update item becuase the same
    // item may belong to multiple selections. So we update all instances.

    for (const menuEntry of this.unfilteredMenu.uncategorizedMenuEntries || []) {
      this.applyItemUpdateForMenuEntry(item, menuEntry, itemUpdater);
    }

    for (const categoryLevel1 of this.unfilteredMenu.categories || []) {
      if (categoryLevel1.menuEntries) {
        for (const menuEntry of categoryLevel1.menuEntries) {
          this.applyItemUpdateForMenuEntry(item, menuEntry, itemUpdater);
        }
      }
      if (categoryLevel1.categories) {
        for (const categoryLevel2 of categoryLevel1.categories) {
          if (categoryLevel2.menuEntries) {
            for (const menuEntry of categoryLevel2.menuEntries) {
              this.applyItemUpdateForMenuEntry(item, menuEntry, itemUpdater);
            }
          }
          if (categoryLevel2.categories) {
            for (const categoryLevel3 of categoryLevel2.categories) {
              if (categoryLevel3.menuEntries) {
                for (const menuEntry of categoryLevel3.menuEntries) {
                  this.applyItemUpdateForMenuEntry(item, menuEntry, itemUpdater);
                }
              }
            }
          }
        }
      }
    }

    this.menuChanged();
  }

  private applyItemUpdateForMenuEntry(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      itemUpdater: Consumer<proto.waiternow.common.StructuredMenuProto.ItemProto>): void {
    for (const itemSelection of this.getMenuEntryItemSelections(menuEntry)) {
      for (const _item of itemSelection.items || []) {
        if (_item.id == item.id) {
          itemUpdater(_item as proto.waiternow.common.StructuredMenuProto.ItemProto);
        }
      }
    }
    for (const _item of this.getMenuEntryRemovableIngredients(menuEntry)) {
      if (_item.id == item.id) {
        itemUpdater(_item as proto.waiternow.common.StructuredMenuProto.ItemProto);
      }
    }
  }

  private deleteItemSelectionSpecIfNotUsed(itemSelectionId: string | null | undefined): void {
    if (!itemSelectionId) {
      return;
    }
    for (const menuItemSpec of this.menuSpec.menuItems) {
      if (Util.arrayContains(itemSelectionId, menuItemSpec.itemSelectionIds)) {
        return;
      }
    }
    const itemSelectionSpec = MenuEditorUtil.findItemSelectionSpec(itemSelectionId, this.menuSpec);
    if (!itemSelectionSpec) {
      return;
    }
    this.menuSpec.itemSelections = this.menuSpec.itemSelections.filter(itemSelectionSpec => itemSelectionSpec.id != itemSelectionId);

    for (const itemSpecId of itemSelectionSpec.itemIds || []) {
      this.deleteItemSpecIfNotUsed(itemSpecId);
    }
  }

  private deleteItemSpecIfNotUsed(itemId: string | null | undefined): void {
    if (!itemId) {
      return;
    }
    for (const menuItemSpec of this.menuSpec.menuItems) {
      if (Util.arrayContains(itemId, menuItemSpec.removableIngredientItemIds)) {
        return;
      }
    }
    for (const itemSelectionSpec of this.menuSpec.itemSelections) {
      if (Util.arrayContains(itemId, itemSelectionSpec.itemIds)) {
        return;
      }
    }
    this.menuSpec.items = this.menuSpec.items.filter(itemSpec => itemSpec.id != itemId);
  }

  public moveCategoryUp(category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): void {
    const categorySpecIndex = MenuEditorUtil.findCategorySpecIndex(category.id, this.menuSpec);
    Util.swap(categorySpecIndex -1, categorySpecIndex, this.menuSpec.categories);
    if (this.unfilteredMenu.categories) {
      const categoryIndex = MenuEditorUtil.findCategoryIndex(category.id, this.unfilteredMenu);
      Util.swap(categoryIndex - 1, categoryIndex, this.unfilteredMenu.categories);
    }
    this.menuChanged();
  }

  public moveCategoryDown(category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): void {
    const categorySpecIndex = MenuEditorUtil.findCategorySpecIndex(category.id, this.menuSpec);
    Util.swap(categorySpecIndex - 1, categorySpecIndex, this.menuSpec.categories);
    if (this.unfilteredMenu.categories) {
      const categoryIndex = MenuEditorUtil.findCategoryIndex(category.id, this.unfilteredMenu);
      Util.swap(categoryIndex, categoryIndex + 1, this.unfilteredMenu.categories);
    }
    this.menuChanged();
  }

  public moveMenuEntryUp(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): void {
    if (!category.menuEntries) {
      return;
    }
    // Even though we make sure the menu entry is moved up within the category, other categories the menu entry belongs to may be affected.
    const menuEntryId = this.getMenuEntryId(menuEntry);
    const menuEntryIndexInCategory = MenuEditorUtil.findMenuEntryIndex(menuEntryId, category);
    const previousMenuEntryIdInCategory = this.getMenuEntryId(category.menuEntries[menuEntryIndexInCategory - 1]);
    const menuItemSpecIndex = MenuEditorUtil.findMenuItemSpecIndex(menuEntryId, this.menuSpec);

    // TODO: add support for combos

    // We will move menuItemSpec to the position before previousMenuItemSpec; we don't swap them.
    const menuItemSpec = this.menuSpec.menuItems[menuItemSpecIndex];
    const newMenuItemSpecs: Array<proto.waiternow.common.StructuredMenuSpecProto.IMenuItemProto> = [];
    for (const _menuItemSpec of this.menuSpec.menuItems) {
      if (_menuItemSpec.id == menuEntryId) {
        continue;
      }
      if (_menuItemSpec.id == previousMenuEntryIdInCategory) {
        newMenuItemSpecs.push(menuItemSpec);
      }
      newMenuItemSpecs.push(_menuItemSpec);
    }
    this.menuSpec.menuItems = newMenuItemSpecs;

    if (this.unfilteredMenu.categories) {
      Util.swap(menuEntryIndexInCategory - 1, menuEntryIndexInCategory, category.menuEntries);
    }

    this.menuChanged();
  }

  public moveMenuEntryDown(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): void {
    if (!category.menuEntries) {
      return;
    }
    // Even though we make sure the menu entry is moved up within the category, other categories the menu entry belongs to may be affected.
    const menuEntryId = this.getMenuEntryId(menuEntry);
    const menuEntryIndexInCategory = MenuEditorUtil.findMenuEntryIndex(menuEntryId, category);
    const nextMenuEntryIdInCategory = this.getMenuEntryId(category.menuEntries[menuEntryIndexInCategory + 1]);
    const menuItemSpecIndex = MenuEditorUtil.findMenuItemSpecIndex(menuEntryId, this.menuSpec);

    // TODO: add support for combos

    // We will move menuItemSpec to the position after nextMenuEntryIdInCategory, we don't swap them
    const menuItemSpec = this.menuSpec.menuItems[menuItemSpecIndex];
    const newMenuItemSpecs: Array<proto.waiternow.common.StructuredMenuSpecProto.IMenuItemProto> = [];
    for (const _menuItemSpec of this.menuSpec.menuItems) {
      if (_menuItemSpec.id == menuEntryId) {
        continue;
      }
      if (_menuItemSpec.id == nextMenuEntryIdInCategory) {
        newMenuItemSpecs.push(_menuItemSpec);
        newMenuItemSpecs.push(menuItemSpec);
      } else {
        newMenuItemSpecs.push(_menuItemSpec);
      }
    }
    this.menuSpec.menuItems = newMenuItemSpecs;

    if (this.unfilteredMenu.categories) {
      Util.swap(menuEntryIndexInCategory, menuEntryIndexInCategory + 1, category.menuEntries);
    }

    this.menuChanged();
  }

  public moveItemSelectionInMenuEntryUp(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        const itemSelectionSpecIndex = MenuEditorUtil.findItemSelectionSpecIndexInMenuItemSpec(itemSelection.id, menuItemSpec);
        Util.swap(itemSelectionSpecIndex - 1, itemSelectionSpecIndex, menuItemSpec.itemSelectionIds);
      },
      comboSpec => {
      },
      menuItem => {
        const itemSelectionIndex = MenuEditorUtil.findItemSelectionIndex(itemSelection.id, menuItem);
        Util.swap(itemSelectionIndex - 1, itemSelectionIndex, menuItem.itemSelections);
      },
      combo => {
      }
    );
    this.menuChanged();
  }

  public moveItemSelectionInMenuEntryDown(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        const itemSelectionSpecIndex = MenuEditorUtil.findItemSelectionSpecIndexInMenuItemSpec(itemSelection.id, menuItemSpec);
        Util.swap(itemSelectionSpecIndex, itemSelectionSpecIndex + 1, menuItemSpec.itemSelectionIds);
      },
      comboSpec => {
      },
      menuItem => {
        const itemSelectionIndex = MenuEditorUtil.findItemSelectionIndex(itemSelection.id, menuItem);
        Util.swap(itemSelectionIndex, itemSelectionIndex + 1, menuItem.itemSelections);
      },
      combo => {
      }
    );
    this.menuChanged();
  }

  public moveItemInItemSelectionUp(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        const itemSelectionSpec = MenuEditorUtil.findItemSelectionSpec(itemSelection.id, this.menuSpec);
        if (!itemSelectionSpec || !itemSelectionSpec.itemIds) {
          return;
        }
        const itemIndex = MenuEditorUtil.findItemSpecIndexInItemSelectionSpec(item.id, itemSelectionSpec);
        Util.swap(itemIndex - 1, itemIndex, itemSelectionSpec.itemIds);
      },
      comboSpec => {
      },
      menuItem => {
        if (!itemSelection.items) {
          return;
        }
        const itemIndex = MenuEditorUtil.findItemIndexInItemSelection(item.id, itemSelection);
        Util.swap(itemIndex - 1, itemIndex, itemSelection.items);
      },
      combo => {
      }
    );
    this.menuChanged();
  }

  public moveItemInItemSelectionDown(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        const itemSelectionSpec = MenuEditorUtil.findItemSelectionSpec(itemSelection.id, this.menuSpec);
        if (!itemSelectionSpec || !itemSelectionSpec.itemIds) {
          return;
        }
        const itemIndex = MenuEditorUtil.findItemSpecIndexInItemSelectionSpec(item.id, itemSelectionSpec);
        Util.swap(itemIndex, itemIndex + 1, itemSelectionSpec.itemIds);
      },
      comboSpec => {
      },
      menuItem => {
        if (!itemSelection.items) {
          return;
        }
        const itemIndex = MenuEditorUtil.findItemIndexInItemSelection(item.id, itemSelection);
        Util.swap(itemIndex, itemIndex + 1, itemSelection.items);
      },
      combo => {
      }
    );
    this.menuChanged();
  }

  public moveItemInRemovableIngredientsUp(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        if (!menuItemSpec.removableIngredientItemIds) {
          return;
        }
        const itemIndex = MenuEditorUtil.findItemSpecIndexInRemovableIngredients(item.id, menuItemSpec);
        Util.swap(itemIndex - 1, itemIndex, menuItemSpec.removableIngredientItemIds);
      },
      comboSpec => {
      },
      menuItem => {
        if (!menuItem.removableIngredients) {
          return;
        }
        const itemIndex = MenuEditorUtil.findItemIndexInRemovableIngredients(item.id, menuItem);
        Util.swap(itemIndex - 1, itemIndex, menuItem.removableIngredients);
      },
      combo => {
      }
    );
    this.menuChanged();
  }

  public moveItemInRemovableIngredientsDown(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        if (!menuItemSpec.removableIngredientItemIds) {
          return;
        }
        const itemIndex = MenuEditorUtil.findItemSpecIndexInRemovableIngredients(item.id, menuItemSpec);
        Util.swap(itemIndex, itemIndex + 1, menuItemSpec.removableIngredientItemIds);
      },
      comboSpec => {
      },
      menuItem => {
        if (!menuItem.removableIngredients) {
          return;
        }
        const itemIndex = MenuEditorUtil.findItemIndexInRemovableIngredients(item.id, menuItem);
        Util.swap(itemIndex, itemIndex + 1, menuItem.removableIngredients);
      },
      combo => {
      }
    );
    this.menuChanged();
  }

  public updateCategory(category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): void {
    const inputDialogData: CategoryEditorDialogData = {
      category: MenuEditorUtil.categoryToEditorCategory(category)
    }
    this.categoryEditorDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        for (const categorySpec of this.menuSpec.categories) {
          if (categorySpec.id == category.id) {
            categorySpec.name = ProtoUtil.createTextProto(outputDialogData.category.nameEn, outputDialogData.category.nameEs);
            category.name = categorySpec.name;
            this.menuChanged();
            break;
          }
        }
      }
    );
  }

  public updateMenuEntryImage(fileSelectorOutput: FileSelectorOutput<proto.waiternow.common.StructuredMenuProto.IMenuEntryProto>): void {
    if (fileSelectorOutput.file) {
      const closeProgressDialog = this.dialogService.openProgressDialog();
      this.backendService.addImageToStructuredMenu(
        new BusinessOrLocationId(this.businessId, this.locationId),
        fileSelectorOutput.file,
        /* onSuccess= */ imageProto => {
          closeProgressDialog();
          if (fileSelectorOutput.userData) {
            this.applyMenuEntryUpdate(
              fileSelectorOutput.userData,
              menuItemSpec => {
                menuItemSpec.image = imageProto;
              },
              comboSpec => {
                comboSpec.image = imageProto;
              },
              menuItem => {
                menuItem.image = imageProto;
              },
              combo => {
                combo.image = imageProto;
              }
            );
          }
        },
        /* onError */ error => {
          closeProgressDialog();
          this.translateService.get('error_uploading_image').subscribe(text => this.toastService.error(text));
        }
      );
    }
  }

  public deleteMenuEntryImage(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        menuItemSpec.image = undefined;
      },
      comboSpec => {
        comboSpec.image = undefined;
      },
      menuItem => {
        menuItem.image = undefined;
      },
      combo => {
        combo.image = undefined;
      }
    );
  }

  public addMenuEntry(): void {
    const inputDialogData: MenuItemEditorDialogData = {
      menuItem: {},
      allCategories: MenuEditorUtil.getAllCategorySpecsAsEditorCategories(this.menuSpec)
    }
    this.menuItemEditorDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        const id = this.generateMenuItemId();
        const name = ProtoUtil.createTextProto(outputDialogData.menuItem.nameEn, outputDialogData.menuItem.nameEs);
        const price = outputDialogData.menuItem.price ? ProtoUtil.pickerMoneyToMoneyProto(outputDialogData.menuItem.price) : undefined;
        const description = ProtoUtil.createTextProto(outputDialogData.menuItem.descriptionEn, outputDialogData.menuItem.descriptionEs);
        const containsAlcohol = outputDialogData.menuItem.containsAlcohol ? true : false;
        const menuItemSpec = new proto.waiternow.common.StructuredMenuSpecProto.MenuItemProto();
        menuItemSpec.id = id;
        menuItemSpec.name = name;
        menuItemSpec.price = price;
        menuItemSpec.description = description;
        menuItemSpec.containsAlcohol = containsAlcohol;
        this.menuSpec.menuItems.push(menuItemSpec);

        const menuEntry = new proto.waiternow.common.StructuredMenuProto.MenuEntryProto();
        // TODO: add support for combos
        menuEntry.menuItem = new proto.waiternow.common.StructuredMenuProto.MenuItemProto();
        menuEntry.menuItem.id = id;
        menuEntry.menuItem.name = name;
        menuEntry.menuItem.price = price;
        menuEntry.menuItem.description = description;
        menuEntry.menuItem.containsAlcohol = containsAlcohol;
        this.consolidateMenuEntryCategories(menuEntry, outputDialogData.menuItem.categories || []);
      }
    );
  }

  public updateMenuEntry(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    // TODO: add support for combos
    if (!menuEntry.menuItem) {
      return;
    }
    const inputDialogData: MenuItemEditorDialogData = {
      menuItem: {
        id: Util.safeString(this.getMenuEntryId(menuEntry)),
        nameEn: Formatter.getText(menuEntry.menuItem.name, proto.waiternow.common.Language.ENGLISH),
        nameEs: Formatter.getText(menuEntry.menuItem.name, proto.waiternow.common.Language.SPANISH),
        price: menuEntry.menuItem.price ? ProtoUtil.moneyProtoToPickerMoney(menuEntry.menuItem.price) : undefined,
        descriptionEn: Formatter.getText(menuEntry.menuItem.description, proto.waiternow.common.Language.ENGLISH),
        descriptionEs: Formatter.getText(menuEntry.menuItem.description, proto.waiternow.common.Language.SPANISH),
        containsAlcohol: this.containsAlcohol(menuEntry),
        categories: MenuEditorUtil.getMenuEntryCategoriesAsEditorCategories(menuEntry, this.menuSpec),
      },
      allCategories: MenuEditorUtil.getAllCategorySpecsAsEditorCategories(this.menuSpec)
    }
    this.menuItemEditorDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        const name = ProtoUtil.createTextProto(outputDialogData.menuItem.nameEn, outputDialogData.menuItem.nameEs);
        const price = outputDialogData.menuItem.price ? ProtoUtil.pickerMoneyToMoneyProto(outputDialogData.menuItem.price) : undefined;
        const description = ProtoUtil.createTextProto(outputDialogData.menuItem.descriptionEn, outputDialogData.menuItem.descriptionEs);
        const containsAlcohol = outputDialogData.menuItem.containsAlcohol ? true : false;
        this.applyMenuEntryUpdate(
          menuEntry,
          menuItemSpec => {
            menuItemSpec.name = name;
            menuItemSpec.price = price
            menuItemSpec.description = description
            menuItemSpec.containsAlcohol = containsAlcohol;
          },
          comboSpec => {
            comboSpec.name = name;
            comboSpec.price = price
            comboSpec.description = description
          },
          menuItem => {
            menuItem.name = name;
            menuItem.price = price
            menuItem.description = description
            menuItem.containsAlcohol = containsAlcohol;
            // TODO: contains alcohol goes to a menu item
          },
          combo => {
            combo.name = name;
            combo.price = price
            combo.description = description
            // TODO: contains alcohol goes to a menu item
          }
        );
        this.consolidateMenuEntryCategories(menuEntry, outputDialogData.menuItem.categories || []);
      }
    );
  }

  private consolidateMenuEntryCategories(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto, newMenuEntryCategories: Array<EditorCategory>) {
    if (newMenuEntryCategories.length == 0) {
      return;
    }

    const menuEntryId = this.getMenuEntryId(menuEntry);

    const currentMenuEntryCategoryIds: Array<string> = MenuEditorUtil.getMenuEntryCategoryIds(menuEntry, this.menuSpec);
    const newMenuEntryExistentCategoryIds: Array<string> = newMenuEntryCategories.filter(category => category.id ? true : false).map(category => Util.safeString(category.id));

    const existentCategoryIdsToAddToMenuItem: Array<string> = newMenuEntryExistentCategoryIds.filter(categoryId => !Util.arrayContains(categoryId, currentMenuEntryCategoryIds));
    const nonExistentCategoriesToAddToMenuItem: Array<EditorCategory> = newMenuEntryCategories.filter(category => !category.id ? true : false);
    const existentCategoryIdsToRemoveFromMenuEntry: Array<string> = currentMenuEntryCategoryIds.filter(categoryId => !Util.arrayContains(categoryId, newMenuEntryExistentCategoryIds));
    const categoryIdsToRemoveFromMenu: Array<string> = [];
    for (const potentialCategoryIdToRemoveFromMenu of existentCategoryIdsToRemoveFromMenuEntry) {
      let removeCategoryFromMenu = true;
      for (const menuItemSpec of this.menuSpec.menuItems || []) {
        if (menuItemSpec.id == menuEntryId) {
          continue;
        }
        if (Util.arrayContains(potentialCategoryIdToRemoveFromMenu, menuItemSpec.categoryIds)) {
          removeCategoryFromMenu = false;
          break;
        }
      }
      if (removeCategoryFromMenu) {
        for (const comboSpec of this.menuSpec.combos || []) {
          if (comboSpec.id == menuEntryId) {
            continue;
          }
          if (Util.arrayContains(potentialCategoryIdToRemoveFromMenu, comboSpec.categoryIds)) {
            removeCategoryFromMenu = false;
            break;
          }
        }
      }
      if (removeCategoryFromMenu) {
        categoryIdsToRemoveFromMenu.push(potentialCategoryIdToRemoveFromMenu);
      }
    }

    for (const category of nonExistentCategoriesToAddToMenuItem){
      category.id = this.generateCategoryId();
    }
    const newMenuEntryCategoryIds: Array<string> =[
      ...currentMenuEntryCategoryIds.filter(cateogyId => !Util.arrayContains(cateogyId, existentCategoryIdsToRemoveFromMenuEntry)),
      ...existentCategoryIdsToAddToMenuItem,
      ...nonExistentCategoriesToAddToMenuItem.map(category => Util.safeString(category.id))
    ];

    // TODO Remove
    /*
    console.log('currentMenuEntryCategoryIds:');
    console.log(currentMenuEntryCategoryIds);
    console.log('newMenuEntryExistentCategoryIds:');
    console.log(newMenuEntryExistentCategoryIds);
    console.log('existentCategoryIdsToAddToMenuItem:');
    console.log(existentCategoryIdsToAddToMenuItem);
    console.log('nonExistentCategoriesToAddToMenuItem:');
    console.log(nonExistentCategoriesToAddToMenuItem);
    console.log('existentCategoryIdsToRemoveFromMenuEntry:');
    console.log(existentCategoryIdsToRemoveFromMenuEntry);
    console.log('categoryIdsToRemoveFromMenu:');
    console.log(categoryIdsToRemoveFromMenu);
    console.log('newMenuEntryCategoryIds:');
    console.log(newMenuEntryCategoryIds);
    */

    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        menuItemSpec.categoryIds = newMenuEntryCategoryIds;
      },
      comboSpec => {
        comboSpec.categoryIds = newMenuEntryCategoryIds;
      },
      menuItem => {
      },
      combo => {
      }
    );

    // --------
    // Deletes Categories From Menu
    // --------

    this.menuSpec.categories = this.menuSpec.categories.filter(categorySpec => !Util.arrayContains(categorySpec.id, categoryIdsToRemoveFromMenu));
    // TODO: Add support for category levels 2 and 3
    this.unfilteredMenu.categories = this.unfilteredMenu.categories?.filter(category => !Util.arrayContains(category.id, categoryIdsToRemoveFromMenu));

    // --------
    // Adds New Categories to Menu
    // --------

    for (const category of nonExistentCategoriesToAddToMenuItem) {
      const categorySpec = new proto.waiternow.common.StructuredMenuSpecProto.CategoryProto();
      categorySpec.id = Util.safeString(category.id);
      categorySpec.name = ProtoUtil.createTextProto(category.nameEn, category.nameEs);
      if (!this.menuSpec.categories) {
        this.menuSpec.categories = [];
      }
      this.menuSpec.categories.push(categorySpec);

      // TODO: Add support for category levels 2 and 3
      const categoryLevel1 = new proto.waiternow.common.StructuredMenuProto.CategoryLevel1Proto();
      categoryLevel1.id = Util.safeString(category.id);
      categoryLevel1.name = ProtoUtil.createTextProto(category.nameEn, category.nameEs);
      categoryLevel1.menuEntries = [];
      categoryLevel1.menuEntries.push(menuEntry);
      if (!this.unfilteredMenu.categories) {
        this.unfilteredMenu.categories = [];
      }
      this.unfilteredMenu.categories.push(categoryLevel1);
    }

    // --------
    // Removes the menu entry from the categories level 1
    // --------

    for (const cetegoryLevel1Id of existentCategoryIdsToRemoveFromMenuEntry) {
      for (const cetegoryLevel1 of this.unfilteredMenu.categories || []) {
        if (cetegoryLevel1Id == cetegoryLevel1.id) {
          cetegoryLevel1.menuEntries = cetegoryLevel1.menuEntries?.filter(categoryMenuEntry => this.getMenuEntryId(categoryMenuEntry) != menuEntryId);
          break;
        }
      }
    }

    // --------
    // Adds the menu entry to the new categories level 1
    // --------

    const menuItemSpecsIndex = new Map<string, number>();
    for (let i = 0; i < this.menuSpec.menuItems.length ; i++) {
      menuItemSpecsIndex.set(Util.safeString(this.menuSpec.menuItems[i].id), i);
    }
    for (const cetegoryLevel1Id of existentCategoryIdsToAddToMenuItem) {
      for (const cetegoryLevel1 of this.unfilteredMenu.categories || []) {
        if (cetegoryLevel1Id == cetegoryLevel1.id) {
          // We need to add thge menu entry in the position where the denormalizer would put it
          // The position is the order defined by menuSpec.menuItems
          const newCategoryLevel1MenuItems: Array<proto.waiternow.common.StructuredMenuProto.IMenuEntryProto> = [];
          let added = false;
          const menuEntryIndex: number = menuItemSpecsIndex.get(menuEntryId) || 0;
          for (const categoryMenuEntry of cetegoryLevel1.menuEntries || []) {
            const categoryMenuEntryIndex: number = menuItemSpecsIndex.get(this.getMenuEntryId(categoryMenuEntry)) || 0;
            if (!added && menuEntryIndex < categoryMenuEntryIndex) {
              newCategoryLevel1MenuItems.push(menuEntry);
              added = true;
            }
            newCategoryLevel1MenuItems.push(categoryMenuEntry);
          }
          if (!added) {
            newCategoryLevel1MenuItems.push(menuEntry);
          }
          cetegoryLevel1.menuEntries = newCategoryLevel1MenuItems;
          break;
        }
      }
    }

    this.menuChanged();
    this.filterMenu();
  }

  public deleteMenuEntry(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.translateService.get('confirmation_delete', { subject: this.getMenuEntryName(menuEntry) }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          const menuEntryId = this.getMenuEntryId(menuEntry);
          const menuItemSpec = MenuEditorUtil.findMenuItemSpec(menuEntryId, this.menuSpec);
          if (!menuItemSpec) {
            return;
          }
          this.menuSpec.menuItems = this.menuSpec.menuItems.filter(_menuItemSpec => _menuItemSpec.id != menuEntryId);

          // TODO: add support for categories level 2 and level 3
          const newCategriesLevel1: Array<proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto> = [];
          for (const categoryLevel1 of this.unfilteredMenu.categories || []) {
            if (Util.arrayContains(categoryLevel1.id, menuItemSpec.categoryIds)) {
              categoryLevel1.menuEntries = categoryLevel1.menuEntries?.filter(_menuEntry => this.getMenuEntryId(_menuEntry) != menuEntryId);
              if (categoryLevel1.menuEntries && categoryLevel1.menuEntries.length > 0) {
                newCategriesLevel1.push(categoryLevel1);
              }
            } else {
              newCategriesLevel1.push(categoryLevel1);
            }
          }
          this.unfilteredMenu.categories = newCategriesLevel1;
          this.selectedMenuEntry = undefined;

          for (const itemSelectionId of menuItemSpec.itemSelectionIds || []) {
            this.deleteItemSelectionSpecIfNotUsed(itemSelectionId);
          }
          for (const itemSpecId of menuItemSpec.removableIngredientItemIds || []) {
            this.deleteItemSpecIfNotUsed(itemSpecId);
          }

          this.menuChanged();
        },
        /* onNo= */ () => {
        });
    });
  }

  public addItemSelection(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    const inputDialogData: SelectItemSelectionDialogData = {
      itemSelection: { },
      allItemSelections: MenuEditorUtil.getAllItemSelectionSpecsAsEditorItemSelections(
        this.menuSpec,
        /* itemSelectionIdsToExclude= */ this.getMenuEntryItemSelections(menuEntry).map(itemSelection => Util.safeString(itemSelection.id))),
      menuSpec: this.menuSpec
    }
    this.selectItemSelectionDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        if (!outputDialogData.itemSelection) {
          return;
        }
        let itemSelectionId = outputDialogData.itemSelection?.id;
        if (!itemSelectionId) {
          itemSelectionId = this.generateItemSelectionId();
          const itemSelectonSpec = MenuEditorUtil.editorItemSelectionToItemSelectionSpec(outputDialogData.itemSelection);
          itemSelectonSpec.id = itemSelectionId;
          this.menuSpec.itemSelections.push(itemSelectonSpec);

          const itemSelecton = MenuEditorUtil.editorItemSelectionToItemSelection(outputDialogData.itemSelection);
          itemSelecton.id = itemSelectionId;

          const itemIds: Array<string> = [];
          for(const item of outputDialogData.itemSelection.items || []) {
            if (!item.id) {
              item.id = this.generateItemId();
              // We need to add the item here so the next call to generateItemId() considers
              // the item just created, otherwise duplicate ids are generated
              const itemSpec = new proto.waiternow.common.StructuredMenuSpecProto.ItemProto();
              itemSpec.id = Util.safeString(item.id);

              itemSpec.name = ProtoUtil.createTextProto(item.nameEn, item.nameEs);
              itemSpec.price = item.price ? ProtoUtil.pickerMoneyToMoneyProto(item.price) : undefined;
              this.menuSpec.items.push(itemSpec);
              }
            itemIds.push(item.id);
          }

          itemSelectonSpec.itemIds = itemIds;

          itemSelecton.items = [];
          // Items to create already have an id in outputDialogData.itemSelection.items
          for (const editorItem of outputDialogData.itemSelection.items || []) {
            itemSelecton.items.push(MenuEditorUtil.editorItemToItem(editorItem));
          }

          this.applyMenuEntryUpdate(
            menuEntry,
            menuItemSpec => {
              if (!menuItemSpec.itemSelectionIds) {
                menuItemSpec.itemSelectionIds = [];
              }
              menuItemSpec.itemSelectionIds.push(Util.safeString(itemSelectionId));
            },
            comboSpec => {
            },
            menuItem => {
              if (!menuItem.itemSelections) {
                menuItem.itemSelections = [];
              }
              menuItem.itemSelections.push(itemSelecton);
            },
            combo => {
            }
          );
        } else {
          const itemSelecton = MenuEditorUtil.editorItemSelectionToItemSelection(outputDialogData.itemSelection);
          itemSelecton.items = [];
          for (const editorItem of outputDialogData.itemSelection.items || []) {
            itemSelecton.items.push(MenuEditorUtil.editorItemToItem(editorItem));
          }
          this.applyMenuEntryUpdate(
            menuEntry,
            menuItemSpec => {
              if (!menuItemSpec.itemSelectionIds) {
                menuItemSpec.itemSelectionIds = [];
              }
              menuItemSpec.itemSelectionIds.push(Util.safeString(itemSelectionId));
            },
            comboSpec => {
            },
            menuItem => {
              if (!menuItem.itemSelections) {
                menuItem.itemSelections = [];
              }
              menuItem.itemSelections.push(itemSelecton);
            },
            combo => {
            }
          );
        }
      }
    );
  }

  public updateItemSelection(itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): void {
    const inputDialogData: ItemSelectionEditorDialogData = {
      itemSelection: MenuEditorUtil.itemSelectionToEditorItemSelection(itemSelection),
      allowChaningItems: false,
      menuSpec: this.menuSpec
    }

    this.itemSelectionEditorDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        const newItemSelectionSpec = MenuEditorUtil.editorItemSelectionToItemSelectionSpec(outputDialogData.itemSelection);
        this.applyItemSelectionUpdate(
          itemSelection,
          itemSelectionSpec => {
            itemSelectionSpec.title = newItemSelectionSpec.title;
            itemSelectionSpec.isRequired = newItemSelectionSpec.isRequired;
            itemSelectionSpec.selectionType = newItemSelectionSpec.selectionType;
            itemSelectionSpec.maxSelections = newItemSelectionSpec.maxSelections;
          },
          itemSelection => {
            itemSelection.title = newItemSelectionSpec.title;
            itemSelection.isRequired = newItemSelectionSpec.isRequired;
            itemSelection.selectionType = newItemSelectionSpec.selectionType;
            itemSelection.maxSelections = newItemSelectionSpec.maxSelections;
          }
        );
      }
    );
  }

  public updateItem(item: proto.waiternow.common.StructuredMenuProto.IItemProto): void {
    const inputDialogData: ItemEditorDialogData = {
      item: {
        id: Util.safeString(item.id),
        nameEn: Formatter.getText(item.name, proto.waiternow.common.Language.ENGLISH),
        nameEs: Formatter.getText(item.name, proto.waiternow.common.Language.SPANISH),
        price: item.price ? ProtoUtil.moneyProtoToPickerMoney(item.price) : undefined
      },
      menuSpec: this.menuSpec
    }
    this.itemEditorDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        const name = ProtoUtil.createTextProto(outputDialogData.item.nameEn, outputDialogData.item.nameEs);
        const price = outputDialogData.item.price ? ProtoUtil.pickerMoneyToMoneyProto(outputDialogData.item.price) : undefined;
        this.applyItemUpdate(
          item,
          itemSpec => {
            itemSpec.name = name;
            itemSpec.price = price
          },
          item => {
            item.name = name;
            item.price = price
          }
        );
      }
    );
  }

  public selectItemForRemovableIngredients(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    const originalSelectedItems = MenuEditorUtil.getMenuEntryRemovableIngredientsAsEditorItems(menuEntry);
    const inputDialogData: SelectItemDialogData = {
      selectedItems: Util.cloneArray(originalSelectedItems),
      allItems: MenuEditorUtil.getAllItemSpecsAsEditorItems(this.menuSpec)
    }
    this.selectItemDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {

        const newItemIds: Array<string> = [];
        for(const item of outputDialogData.selectedItems || []) {
          if (!item.id) {
            item.id = this.generateItemId();
            // We need to add the item here so the next call to generateItemId() considers
            // the item just created, otherwise duplicate ids are generated
            const itemSpec = new proto.waiternow.common.StructuredMenuSpecProto.ItemProto();
            itemSpec.id = Util.safeString(item.id);
            itemSpec.name = ProtoUtil.createTextProto(item.nameEn, item.nameEs);
            itemSpec.price = item.price ? ProtoUtil.pickerMoneyToMoneyProto(item.price) : undefined;
            this.menuSpec.items.push(itemSpec);
          }
          newItemIds.push(item.id);
        }

        this.applyMenuEntryUpdate(
          menuEntry,
          menuItemSpec => {
            menuItemSpec.removableIngredientItemIds = newItemIds;
          },
          comboSpec => {
          },
          menuItem => {
            const newItems: Array<proto.waiternow.common.StructuredMenuProto.IItemProto> = [];
            // Items to create already have an id in outputDialogData.selectedItems
            for (const itemFromDialog of outputDialogData.selectedItems || []) {
              newItems.push(MenuEditorUtil.editorItemToItem(itemFromDialog));
            }
            menuItem.removableIngredients = newItems;
          },
          combo => {
          }
        );
        for (const potentialItemToDelete of originalSelectedItems) {
          if (!Util.arrayContains(potentialItemToDelete.id, newItemIds)) {
            this.deleteItemSpecIfNotUsed(potentialItemToDelete.id);
          }
        }
      }
    );
  }

  public removeItemForRemovableIngredients(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        menuItemSpec.removableIngredientItemIds = menuItemSpec.removableIngredientItemIds.filter(itemId => itemId != item.id);
      },
      comboSpec => {
      },
      menuItem => {
        menuItem.removableIngredients = menuItem.removableIngredients.filter(_item => _item.id != item.id);
      },
      combo => {
      }
    );
    this.deleteItemSpecIfNotUsed(item.id);
  }

  public selectItemForItemSelection(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    const originalSelectedItems = MenuEditorUtil.getItemSelectionItemsAsEditorItems(itemSelection);
    const inputDialogData: SelectItemDialogData = {
      selectedItems: Util.cloneArray(originalSelectedItems),
      allItems: MenuEditorUtil.getAllItemSpecsAsEditorItems(this.menuSpec)
    }
    this.selectItemDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {

        const newItemIds: Array<string> = [];
        for(const item of outputDialogData.selectedItems || []) {
          if (!item.id) {
            item.id = this.generateItemId();
            // We need to add the item here so the next call to generateItemId() considers
            // the item just created, otherwise duplicate ids are generated
            const itemSpec = new proto.waiternow.common.StructuredMenuSpecProto.ItemProto();
            itemSpec.id = Util.safeString(item.id);
            itemSpec.name = ProtoUtil.createTextProto(item.nameEn, item.nameEs);
            itemSpec.price = item.price ? ProtoUtil.pickerMoneyToMoneyProto(item.price) : undefined;
            this.menuSpec.items.push(itemSpec);
          }
          newItemIds.push(item.id);
        }

        const itemSelectionSpec = MenuEditorUtil.findItemSelectionSpec(itemSelection.id, this.menuSpec);
        if (itemSelectionSpec) {
          itemSelectionSpec.itemIds = newItemIds;
        }

        this.applyMenuEntryUpdate(
          menuEntry,
          menuItemSpec => {
          },
          comboSpec => {
          },
          menuItem => {
            for (const _itemSelection of menuItem.itemSelections) {
              if (_itemSelection.id == itemSelection.id){
                const newItems: Array<proto.waiternow.common.StructuredMenuProto.IItemProto> = [];
                // Items to create already have an id in outputDialogData.selectedItems
                for (const itemFromDialog of outputDialogData.selectedItems || []) {
                  newItems.push(MenuEditorUtil.editorItemToItem(itemFromDialog));
                }
                _itemSelection.items = newItems;
                break;
              }
            }
          },
          combo => {
          }
        );
        for (const potentialItemToDelete of originalSelectedItems) {
          if (!Util.arrayContains(potentialItemToDelete.id, newItemIds)) {
            this.deleteItemSpecIfNotUsed(potentialItemToDelete.id);
          }
        }
      }
    );
  }

  public removeItemForItemSelection(
      item: proto.waiternow.common.StructuredMenuProto.IItemProto,
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    const itemSelectionSpec = MenuEditorUtil.findItemSelectionSpec(itemSelection.id, this.menuSpec);
    let deleteItemSelection = false;
    if (itemSelectionSpec && itemSelectionSpec.itemIds) {
      itemSelectionSpec.itemIds = itemSelectionSpec.itemIds.filter(itemId => itemId != item.id);
      if (itemSelectionSpec.itemIds.length == 0) {
        this.menuSpec.itemSelections = this.menuSpec.itemSelections.filter(_itemSelectionSpec => _itemSelectionSpec.id != itemSelection.id);
        deleteItemSelection = true;
      }
    }

    this.applyMenuEntryUpdate(
      menuEntry,
      menuItemSpec => {
        if (deleteItemSelection) {
          menuItemSpec.itemSelectionIds = menuItemSpec.itemSelectionIds.filter(_itemSelectionId => _itemSelectionId != itemSelection.id);
        }
      },
      comboSpec => {
      },
      menuItem => {
        if (deleteItemSelection) {
          menuItem.itemSelections = menuItem.itemSelections.filter(_itemSelection => _itemSelection.id != itemSelection.id);
        } else {
          for (const _itemSelection of menuItem.itemSelections) {
            if (_itemSelection.id == itemSelection.id){
              if (_itemSelection.items) {
                _itemSelection.items = _itemSelection.items.filter(_item => _item.id != item.id);
              }
              break;
            }
          }
        }
      },
      combo => {
      }
    );
    this.deleteItemSpecIfNotUsed(item.id);
  }

  public removeItemSelection(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto,
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    this.translateService.get('confirmation_delete', { subject: this.getNonEmptyText(itemSelection.title) }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          const itemSelectionSpec = MenuEditorUtil.findItemSelectionSpec(itemSelection.id, this.menuSpec);
          if (!itemSelectionSpec) {
            return;
          }
          this.menuSpec.itemSelections = this.menuSpec.itemSelections.filter(_itemSelectionSpec => _itemSelectionSpec.id != itemSelection.id);

          this.applyMenuEntryUpdate(
            menuEntry,
            menuItemSpec => {
              menuItemSpec.itemSelectionIds = menuItemSpec.itemSelectionIds.filter(_itemSelectionId => _itemSelectionId != itemSelection.id);
            },
            comboSpec => {
            },
            menuItem => {
              menuItem.itemSelections = menuItem.itemSelections.filter(_itemSelection => _itemSelection.id != itemSelection.id);
            },
            combo => {
            }
          );
          itemSelectionSpec.itemIds?.forEach(itemId => this.deleteItemSpecIfNotUsed(itemId));
        },
        /* onNo= */ () => {
        });
    });
  }

  public updateMenuEntrySchedule(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
    const inputDialogData: SelectScheduleDialogData = {
      allSchedules: MenuEditorUtil.getAllSchedulesAsEditorSchedules(this.menuSpec),
      menuSpec: this.menuSpec
    }
    const availabilitySchedule = this.getAvailabilitySchedule(menuEntry);
    if (availabilitySchedule) {
      for (const editorSchedule of inputDialogData.allSchedules || []) {
        if (editorSchedule.id == availabilitySchedule.id) {
          // inputDialogData.schedule has to be the same instance as one of inputDialogData.allSchedules
          // so the mat select properly selects the current schedule.
          inputDialogData.schedule = editorSchedule;
          break;
        }
      }
    }
    this.selectScheduleDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        if (outputDialogData.schedule) {
          const newAvailabilitySchedule = MenuEditorUtil.editorScheduleToAvailabilityScheduleProto(outputDialogData.schedule);
          if (!newAvailabilitySchedule.id) {
            newAvailabilitySchedule.id = this.generateScheduleId();
            this.menuSpec.schedules.push(newAvailabilitySchedule);
          } else {
            // We replace the schedule in case it was updated
            const availabilityScheduleSpecIndex = MenuEditorUtil.findAvailabilityScheduleSpecIndex(newAvailabilitySchedule.id, this.menuSpec);
            this.menuSpec.schedules[availabilityScheduleSpecIndex] = newAvailabilitySchedule;
            this.visitMenuEntries(
              menuItem => {
                if (menuItem.availabilitySchedule && menuItem.availabilitySchedule.id == newAvailabilitySchedule.id) {
                  menuItem.availabilitySchedule = newAvailabilitySchedule;
                }
              },
              combo => {
                if (combo.availabilitySchedule && combo.availabilitySchedule.id == newAvailabilitySchedule.id) {
                  combo.availabilitySchedule = newAvailabilitySchedule;
                }
              }
            );
          }
          this.applyMenuEntryUpdate(
            menuEntry,
            menuItemSpec => {
              menuItemSpec.availabilityScheduleId = Util.safeString(newAvailabilitySchedule.id);
            },
            comboSpec => {
              comboSpec.availabilityScheduleId = Util.safeString(newAvailabilitySchedule.id);
            },
            menuItem => {
              menuItem.availabilitySchedule = newAvailabilitySchedule;
            },
            combo => {
              combo.availabilitySchedule = newAvailabilitySchedule;
            }
          );
        } else {
          this.applyMenuEntryUpdate(
            menuEntry,
            menuItemSpec => {
              menuItemSpec.availabilityScheduleId = '';
            },
            comboSpec => {
              comboSpec.availabilityScheduleId = '';
            },
            menuItem => {
              menuItem.availabilitySchedule = undefined;
            },
            combo => {
              combo.availabilitySchedule = undefined;
            }
          );
        }
        if (availabilitySchedule) {
          this.deleteAvailabilityScheduleIfNotUsed(availabilitySchedule.id);
        }
    }
    );
  }

  private deleteAvailabilityScheduleIfNotUsed(scheduleId: string | null | undefined): void {
    if (!scheduleId) {
      return;
    }
    for (const menuItemSpec of this.menuSpec.menuItems) {
      if (scheduleId == menuItemSpec.availabilityScheduleId) {
        return;
      }
    }
    for (const comboSpec of this.menuSpec.combos) {
      if (scheduleId == comboSpec.availabilityScheduleId) {
        return;
      }
    }
    this.menuSpec.schedules = this.menuSpec.schedules.filter(schedule => schedule.id != scheduleId);
  }
}
