import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { SessionService } from '../../services/session.service';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormService } from '../../../general/services/form.service';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { TableLayoutComponent } from 'src/app/general/components/table-layout/table-layout.component';
import { EnvironmentUtil } from '../../util/environment-util';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { ProtoType } from '../../util/constants';
import { MatSelectModule } from '@angular/material/select';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { NavigationService } from '../../services/navigation.service';
import { DeviceService } from 'src/app/general/services/device.service';

@Component({
  selector: 'app-superuser-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    MatExpansionModule,
    TranslateModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    LoadableContentComponent,
    TableLayoutComponent,
    FieldContainerDirective,
    FieldDirective,
    MatSelectModule,
    ActionComponent
  ],
  templateUrl: './superuser-page.component.html',
  styleUrls: ['./superuser-page.component.css', '../../../../styles.css']
})
export class SuperuserPageComponent implements OnInit {
  readonly EnvironmentUtil = EnvironmentUtil;
  readonly PROTO_TYPES: Array<String> = Object.keys(ProtoType);
  readonly PROTO_TYPES_VALUES: Array<String> = Object.values(ProtoType);

  // This is set after the view is initialized
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  orderReceipt = new (class {
    orderId: FormControl = new FormControl('', [Validators.required]);
  })();

  protoRead = new (class {
    baseActionUrl: string = EnvironmentUtil.resolveBackendUrl('/service/debug/proto/read');
    actionUrl: string = this.baseActionUrl + '/' + ProtoType.ORDER;
    protoTypes: Array<String> = Object.keys(ProtoType);
    protoType: FormControl = new FormControl(ProtoType.ORDER);
    protoId: FormControl = new FormControl('', [Validators.required]);
  })();

  protoWrite = new (class {
    baseActionUrl: string = EnvironmentUtil.resolveBackendUrl('/service/debug/proto/write');
    actionUrl: string = this.baseActionUrl + '/' + ProtoType.ORDER;
    protoTypes: Array<String> = Object.keys(ProtoType);
    protoType: FormControl = new FormControl(ProtoType.ORDER);
    textProto: FormControl = new FormControl('', [Validators.required]);
  })();

  generateQrCode = new (class {
    textToEncode: FormControl = new FormControl('', [Validators.required]);
  })();

  userEmailUpdate = new (class {
    userId: FormControl = new FormControl('', [Validators.required]);
    newEmail: FormControl = new FormControl('', [Validators.required]);
  })();

  constructor(
      public formService: FormService,
      public sessionService: SessionService,
      public deviceService: DeviceService,
      private navigationService: NavigationService) {

    this.protoRead.protoType.valueChanges.subscribe(() => {
      this.protoRead.actionUrl = this.protoRead.baseActionUrl + '/' + this.protoRead.protoType.value;
    });

    this.protoWrite.protoType.valueChanges.subscribe(() => {
      this.protoWrite.actionUrl = this.protoWrite.baseActionUrl + '/' + this.protoWrite.protoType.value;
    });
  }

  ngOnInit(): void {
    this.sessionService.enforceAuthentication();
  }

  public openOrderReceipt(orderId: string): void {
    this.navigationService.openInNewTab(EnvironmentUtil.resolveBackendUrl('/service/user/order/receipt/' + orderId));
  }
}
