import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  public logDebug(message?: any, ...optionalParams: any[]): void {
    console.log('[DEBUG] ' + this.getTime());
    console.log(message, optionalParams);
  }

  public logInfo(message?: any, ...optionalParams: any[]): void {
    console.log('[INFO] ' + this.getTime());
    console.log(message, optionalParams);
  }

  public logWarning(message?: any, ...optionalParams: any[]): void {
    console.log('[WARNING] ' + this.getTime());
    console.log(message, optionalParams);
  }

  public logError(message?: any, ...optionalParams: any[]): void {
    console.error('[ERROR] ' + this.getTime());
    console.error(message, optionalParams);
  }

  public logErrorWithCause(cause: any, message?: any, ...optionalParams: any[]): void {
    console.error('[ERROR] ' + this.getTime());
    console.error(message, optionalParams);
    console.error(cause);
  }

  private getTime(): string {
    return new Date().toLocaleString();
  }
}
