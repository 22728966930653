import { ProtoUtil } from '../util/proto-util';
import { Util } from 'src/app/general/util/util';
import { TimeUtil } from 'src/app/general/util/time-util';
import { Function } from 'src/app/general/interfaces/functions';
import * as proto from 'src/proto/compiled-protos';

export class Formatter {

  static formatDate(date: Date | null | undefined): string {
    if (!date) {
      return '';
    }
    return date.toDateString();
  }

  static formatDateTime(date: Date | null | undefined): string {
    if (!date) {
      return '';
    }
    return date.toLocaleString();
  }

  static formatDateProtoAsYear(date: proto.waiternow.common.IDateProto | null | undefined): string {
    if (!date || !date.year) {
      return '';
    }
    return date.year.toString();
  }

  static formatDateProtoAsYearMonth(date: proto.waiternow.common.IDateProto | null | undefined): string {
    if (!date || !date.year || !date.month) {
      return '';
    }
    return date.year.toString() + '-' + date.month.toString().padStart(2, '0');
  }

  static formatDateProto(date: proto.waiternow.common.IDateProto | null | undefined) {
    if (!date || !date.year || !date.month || !date.day) {
      return '';
    }
    return date.year.toString() + '-' + date.month.toString().padStart(2, '0') + '-' + date.day.toString().padStart(2, '0');
  }

  static formatTimeProto(time: proto.waiternow.common.ITimeProto | null | undefined) {
    if (!time) {
      return '';
    }
    const hour: number = time.hour || 0;
    const minute: number = time.minute || 0;
    const second: number = time.second || 0;
    let text = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
    if (second > 0) {
      text += ':' + second.toString().padStart(2, '0');
    }
    return text;
  }

  static formatDateTimeProto(dateTime: proto.waiternow.common.IDateTimeProto | null | undefined) {
    if (!dateTime) {
      return '';
    }
    let text = Formatter.formatDateProto(dateTime.date);
    if (dateTime.time) {
      text += ' ' + Formatter.formatTimeProto(dateTime.time);
    }
    return text;
  }

  static formatTimestampProto(timestamp: proto.google.protobuf.ITimestamp | null | undefined): string {
    if (!timestamp) {
      return '';
    }
    const date: Date = TimeUtil.createDateFromMillis(ProtoUtil.timestampProtoToMillis(timestamp));
    return Formatter.formatDateTime(date);
  }

  static formatDurationProto(duration: proto.google.protobuf.IDuration | null | undefined): string {
    if (!duration) {
      return '';
    }
    let durationMillis = ProtoUtil.durationProtoToMillis(duration);
    return Formatter.formatDurationMillis(durationMillis);
  }

  static formatElapsedTime(date: Date) {
    const now = new Date();
    let elapsedTimeMillis = now.getTime() - date.getTime();
    return Formatter.formatDurationMillis(elapsedTimeMillis);
  }

  static formatDurationMillis(durationMillis: number): string {
    /*
    // strip the ms
    durationMillis /= 1000;
    const seconds = Math.floor(durationMillis % 60);
    // remove seconds
    durationMillis = Math.floor(durationMillis / 60);
    const minutes = Math.floor(durationMillis % 60);
    // remove minutes
    durationMillis = Math.floor(durationMillis / 60);
    const hours = Math.floor(durationMillis % 24);
    // remove hours
    durationMillis = Math.floor(durationMillis / 24);
    // the rest of durationMillis is number of days
    const days = durationMillis;
    */

    const millisInADay = 86400000;
    const millisInAnHour = 3600000;
    const millisInAMinute = 60000;
    const millisInASecond = 1000;

    let str = '';
    {
      const days = Math.floor(durationMillis / millisInADay);
      if (days > 364) {
        const years = days / 365;
        str += years + 'y';
        durationMillis = durationMillis - (years * 365 * millisInADay);
      }
    }
    {
      const days = Math.floor(durationMillis / millisInADay);
      if (days > 0) {
        if (str.length > 0) {
          str += ' ';
        }
        str += days + 'd';
        durationMillis = durationMillis - (days * millisInADay);
      }
    }
    {
      const hours = Math.floor(durationMillis / millisInAnHour);
      if (hours > 0) {
        if (str.length > 0) {
          str += ' ';
        }
        str += hours + 'h';
        durationMillis = durationMillis - (hours * millisInAnHour);
      }
    }
    {
      const minutes = Math.floor(durationMillis / millisInAMinute);
      if (minutes > 0) {
        if (str.length > 0) {
          str += ' ';
        }
        str += minutes + 'm';
        durationMillis = durationMillis - (minutes * millisInAMinute);
      }
    }
    {
      const seconds = Math.floor(durationMillis / millisInASecond);
      if (seconds > 0) {
        if (str.length > 0) {
          str += ' ';
        }
        str += seconds + 's';
        durationMillis = durationMillis - (seconds * millisInASecond);
      }
    }
    /*
    {
      if (durationMillis > 0) {
        if (str.length > 0) {
          str += ' ';
        }
        str += durationMillis + 'ms';
      }
    }
    */

    return str;
  }

  static formatRole(role: proto.waiternow.common.Role | null | undefined): string {
    if (!role) {
      return '';
    }
    switch (role) {
      case proto.waiternow.common.Role.OWNER:
        return 'Owner';
      case proto.waiternow.common.Role.ADMIN:
        return 'Admin';
      case proto.waiternow.common.Role.VIEWER:
        return 'Viewer';
    }
  }

  static formatLanguage(language: proto.waiternow.common.Language | null | undefined): string {
    if (!language) {
      return '';
    }
    switch (language) {
      case proto.waiternow.common.Language.ENGLISH:
        return 'English';
      case proto.waiternow.common.Language.SPANISH:
        return 'Spanish';
    }
  }

  static formatTheme(theme: proto.waiternow.common.Theme | null | undefined): string {
    if (!theme) {
      return '';
    }
    switch (theme) {
      case proto.waiternow.common.Theme.DARK:
        return 'Dark';
      case proto.waiternow.common.Theme.LIGHT:
        return 'Light';
    }
  }

  static formatCountry(country: proto.waiternow.common.Country | null | undefined): string {
    if (!country) {
      return '';
    }
    switch (country) {
      case proto.waiternow.common.Country.UNITED_STATES:
        return 'USA';
      case proto.waiternow.common.Country.MEXICO:
        return 'México';
    }
  }

  static formatAddress(address: proto.waiternow.common.IAddressProto | null | undefined): string {
    if (!address) {
      return '';
    }
    if (address.simpleAddress) {
      return Util.safeString(address.simpleAddress.address);
    } else if (address.structuredAddress) {
      const structuredAddress = address.structuredAddress;
      let strAddress = '';
      if (structuredAddress.street) {
        strAddress += structuredAddress.street;
        if (strAddress && strAddress.charAt(strAddress.length - 1) != '.') {
          strAddress += '.';
        }
      }
      if (structuredAddress.city) {
        strAddress += ' ';
        strAddress += structuredAddress.city;
        if (structuredAddress.state) {
          strAddress += ',';
        } else {
          strAddress += '.';
        }
      }
      if (structuredAddress.state) {
        strAddress += ' ';
        strAddress += structuredAddress.state;
        strAddress += '.';
      }
      if (structuredAddress.postalCode) {
        strAddress += ' ';
        strAddress += structuredAddress.postalCode;
        strAddress += '.';
      }
      if (structuredAddress.otherCountry) {
        strAddress += ' ';
        strAddress += structuredAddress.otherCountry;
        strAddress += '.';
      } else if (structuredAddress.country) {
        strAddress += ' ';
        strAddress += Formatter.formatCountry(structuredAddress.country);
        strAddress += '.';
      }
      return strAddress;
    }
    return '';
  }

  static formatPhoneNumber(phoneNumber: proto.waiternow.common.IPhoneNumberProto | null | undefined): string {
    if (!phoneNumber) {
      return '';
    }
    if (phoneNumber.simplePhoneNumber) {
      return Util.safeString(phoneNumber.simplePhoneNumber.phoneNumber);
    } else if (phoneNumber.structuredPhoneNumber) {
      const structuredPhoneNumber = phoneNumber.structuredPhoneNumber;
      return '+' + structuredPhoneNumber.countryCode + structuredPhoneNumber.number;
    }
    return '';
  }

  static formatClientType(clientType: proto.waiternow.common.DeviceProto.ClientType | null | undefined): string {
    if (!clientType) {
      return '';
    }
    switch (clientType) {
      case proto.waiternow.common.DeviceProto.ClientType.BUSINESS:
        return 'Business';
      case proto.waiternow.common.DeviceProto.ClientType.CONSUMER:
        return 'Consumer';
    }
  }

  static formatDeviceType(deviceType: proto.waiternow.common.DeviceProto.DeviceType | null | undefined): string {
    if (!deviceType) {
      return '';
    }
    switch (deviceType) {
      case proto.waiternow.common.DeviceProto.DeviceType.WEB:
        return 'Web';
      case proto.waiternow.common.DeviceProto.DeviceType.ANDROID:
        return 'Android';
      case proto.waiternow.common.DeviceProto.DeviceType.APPLE:
        return 'Apple';
    }
  }

  static formatPointOfServiceType(type: proto.waiternow.common.PointOfServiceProto.PointOfServiceType | null | undefined): string {
    if (!type) {
      return '';
    }
    switch (type) {
      case proto.waiternow.common.PointOfServiceProto.PointOfServiceType.TABLE:
        return 'Table';
      case proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ROOM:
        return 'Room';
      case proto.waiternow.common.PointOfServiceProto.PointOfServiceType.TERRACE:
        return 'Terrace';
      case proto.waiternow.common.PointOfServiceProto.PointOfServiceType.POOL:
        return 'Pool';
      case proto.waiternow.common.PointOfServiceProto.PointOfServiceType.MENU:
        return 'Menu';
      case proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONLINE_PICKUP:
          return 'Online Pickup';
      case proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONSITE_PICKUP:
        return 'On Site Pickup';
      case proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONLINE:
        return 'Online';
    }
  }

  static formatMonth(month: proto.waiternow.common.Month | null | undefined, language?: proto.waiternow.common.Language): string {
    if (!month) {
      return '';
    }

    const isSpanish = language == proto.waiternow.common.Language.SPANISH;

    switch (month) {
      case proto.waiternow.common.Month.JANUARY:
        return isSpanish ? 'Enero' : 'January';
      case proto.waiternow.common.Month.FEBRUARY:
        return isSpanish ? 'Febrero' : 'February';
      case proto.waiternow.common.Month.MARCH:
        return isSpanish ? 'Marzo' : 'March';
      case proto.waiternow.common.Month.APRIL:
        return isSpanish ? 'Abril' : 'April';
      case proto.waiternow.common.Month.MAY:
        return isSpanish ? 'Mayo' : 'May';
      case proto.waiternow.common.Month.JUNE:
        return isSpanish ? 'Junio' : 'June';
      case proto.waiternow.common.Month.JULY:
        return isSpanish ? 'Julio' : 'July';
      case proto.waiternow.common.Month.AUGUST:
        return isSpanish ? 'Agosto' : 'August';
      case proto.waiternow.common.Month.SEPTEMBER:
        return isSpanish ? 'Septiembre' : 'September';
      case proto.waiternow.common.Month.OCTOBER:
        return isSpanish ? 'Octubre' : 'October';
      case proto.waiternow.common.Month.NOVEMBER:
        return isSpanish ? 'Noviembre' : 'November';
      case proto.waiternow.common.Month.DECEMBER:
        return isSpanish ? 'Diciembre' : 'December';
    }
  }

  static formatMoney(money: proto.waiternow.common.IMoneyProto | null | undefined): string {
    if (!money) {
      return "$0.00";
    }
    let str = '$';
    if (money.units) {
      str += money.units;
    } else {
      str += '0';
    }
    str += '.';
    if (!money.cents || money.cents == 0) {
      str += '00';
    }  else if(money.cents < 10) {
      str += '0' + money.cents;
    } else {
      str += money.cents;
    }
    return str;
  }

  static formatMoneyIncludeCurrency(money: proto.waiternow.common.IMoneyProto | null | undefined): string {
    if (!money) {
      return "";
    }
    return Formatter.formatMoney(money) + ' ' + money.currencyCode;
  }

  static formatMoneyNumber(money: number | null | undefined): string {
    if (!money) {
      return "$0.00";
    }
    return '$' + money.toFixed(2);
  }

  static formatOrderStatus(order: proto.waiternow.common.IOrderProto): string {
    if (!order.status) {
      return '';
    }
    if (order.status.completed) {
      return 'Completed';
    } else if (order.status.printed) {
      return 'Printed';
    } if (order.status.acked) {
      return 'Acked';
    } else if (order.status.received) {
      return 'Received';
    }
    return '';
  }

  static formatOrderPaymentStatus(order: proto.waiternow.common.IOrderProto): string {
    if (!order.status || !order.status.payment) {
      return '';
    }
    const payment = order.status.payment;
    if (!payment.status) {
      return '';
    }
    switch (payment.status) {
      case proto.waiternow.common.PaymentProto.Status.SUCCESSFUL:
        if (payment.partialRefunds && payment.partialRefunds.length > 0) {
          return 'Successful with partial refunds';
        }
        return 'Successful';
      case proto.waiternow.common.PaymentProto.Status.REQUIRES_PAYMENT_METHOD:
        return 'Requires Payment Method';
      case proto.waiternow.common.PaymentProto.Status.REQUIRES_ACTION:
        return 'Requires Action';
      case proto.waiternow.common.PaymentProto.Status.PROCESSING:
        return 'Processing';
      case proto.waiternow.common.PaymentProto.Status.DECLINED:
        return 'Declined';
      case proto.waiternow.common.PaymentProto.Status.CANCELED:
        return 'Canceled';
      case proto.waiternow.common.PaymentProto.Status.REFUNDED:
        return 'Refunded';
      case proto.waiternow.common.PaymentProto.Status.DISPUTED:
        return 'Disputed';
    }
    return '';
  }

  static formatOrderType(order: proto.waiternow.common.IOrderProto): string {
    if (order.redundantData?.isOnsitePickup) {
      return 'Onsite Pickup';
    }
    if (order.redundantData?.isOnlinePickup) {
      return 'Online Pickup';
    }
    if (order.redundantData?.isDelivery) {
      return 'Delivery';
    }
    if (order.status && order.status.payment) {
      return 'Onsite Ordering';
    }
    if (order.waiterRequest) {
      return 'Waiter Request';
    }
    return '';
  }

  static getText(textProto: proto.waiternow.common.ITextProto | null | undefined, language: proto.waiternow.common.Language): string {
    if (!textProto) {
      return '';
    }

    if (!language || textProto.language == language) {
      return Util.safeString(textProto.text);
    }

    if (textProto.localizedTexts) {
      for(const localizedText of textProto.localizedTexts) {
        if (localizedText.language == language) {
          return Util.safeString(localizedText.text);
        }
      }
    }

    return '';
  }

  static formatDerivedQuantityConfig(config: proto.waiternow.common.IDerivedQuantityConfigProto | null | undefined): string {
    if (!config) {
      return '';
    }
    let str = '';
    if (config.fixedAmount) {
      str += 'Fixed amount: ' + Formatter.formatMoney(config.fixedAmount);
    }
    if (config.percentage) {
      if (str.length > 0) {
        str += " & "
      }
      str += 'Percentage: ' + config.percentage.value;
    }
    return str;
  }

  static formatCampaignConditions(conditions: Array<proto.waiternow.common.CampaignProto.IConditionProto> | null | undefined): string {
    if (!conditions) {
      return '';
    }
    let str = '';
    for (let i=0; i < conditions.length; i++) {
      const condition = conditions[i];
      if (condition.rule) {
        switch(condition.rule) {
          case proto.waiternow.common.CampaignProto.ConditionProto.Rule.FIRST_PURCHASE:
            if (str.length > 0) {
              str += ", "
            }
            str += "First Purchase";
            break;
          case proto.waiternow.common.CampaignProto.ConditionProto.Rule.EXCLUDE_ONLINE_ORDERS:
            if (str.length > 0) {
              str += ", "
            }
            str += "Exclude Online Pickup";
            break;
        }
      }
    }
    return str;
  }

  static formatTimeToMinutes(time: proto.waiternow.common.ITimeProto | null | undefined): string {
    if (!time) {
      return '';
    }
    return time.hour?.toString().padStart(2, '0') + ':' + time.minute?.toString().padStart(2, '0');
  }

  /*
    Example to manual test this method:

    const schedule = new proto.waiternow.common.ScheduleProto();

    const dateFrom = new proto.waiternow.common.DateProto();
    dateFrom.day = 1;
    dateFrom.month = proto.waiternow.common.Month.JANUARY;
    dateFrom.year = 2024;
    const dateTo = new proto.waiternow.common.DateProto();
    dateTo.day = 2;
    dateTo.month = proto.waiternow.common.Month.NOVEMBER;
    dateTo.year = 2024;
    const timeFrom = new proto.waiternow.common.TimeProto();
    timeFrom.hour = 9;
    timeFrom.minute = 30;
    timeFrom.second = 59;
    const timeTo = new proto.waiternow.common.TimeProto();
    timeTo.hour = 17;
    const timeRange = new proto.waiternow.common.TimeRangeProto();
    timeRange.from = timeFrom;
    timeRange.to = timeTo;
    const timeRangeWithoutTo = new proto.waiternow.common.TimeRangeProto();
    timeRangeWithoutTo.from = timeFrom;
    const timeRangeWithoutFrom = new proto.waiternow.common.TimeRangeProto();
    timeRangeWithoutFrom.to = timeTo;

    const dateTimeRange1 = new proto.waiternow.common.DateTimeRangeProto();
    dateTimeRange1.from = new proto.waiternow.common.DateTimeProto();
    dateTimeRange1.from.date = dateFrom;
    dateTimeRange1.from.time = timeFrom;
    dateTimeRange1.to = new proto.waiternow.common.DateTimeProto();
    dateTimeRange1.to.date = dateTo;
    dateTimeRange1.to.time = timeTo;
    schedule.dateTimeRanges.push(dateTimeRange1);

    const dateTimeRange2 = new proto.waiternow.common.DateTimeRangeProto();
    dateTimeRange2.from = new proto.waiternow.common.DateTimeProto();
    dateTimeRange2.from.date = dateFrom;
    dateTimeRange2.to = new proto.waiternow.common.DateTimeProto();
    dateTimeRange2.to.date = dateTo;
    schedule.dateTimeRanges.push(dateTimeRange2);

    const dailyRecurrence1 = new proto.waiternow.common.DailyRecurrenceProto();
    dailyRecurrence1.recurrenceType = proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.DAILY;
    dailyRecurrence1.timeRange = timeRange;
    schedule.dailyRecurrences.push(dailyRecurrence1);

    const dailyRecurrence2 = new proto.waiternow.common.DailyRecurrenceProto();
    dailyRecurrence2.recurrenceType = proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKDAYS;
    dailyRecurrence2.timeRange = timeRangeWithoutTo;
    schedule.dailyRecurrences.push(dailyRecurrence2);

    const dailyRecurrence3 = new proto.waiternow.common.DailyRecurrenceProto();
    dailyRecurrence3.recurrenceType = proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKENDS;
    dailyRecurrence3.timeRange = timeRangeWithoutFrom;
    schedule.dailyRecurrences.push(dailyRecurrence3);

    const monthlyRecurrence1 = new proto.waiternow.common.MonthlyRecurrenceProto();
    monthlyRecurrence1.montlyOnDay = 3;
    monthlyRecurrence1.timeRange = timeRange;
    schedule.monthlyRecurrences.push(monthlyRecurrence1);

    const monthlyRecurrence2 = new proto.waiternow.common.MonthlyRecurrenceProto();
    monthlyRecurrence2.montlyOnDay = 3;
    schedule.monthlyRecurrences.push(monthlyRecurrence2);

    const yearlyRecurrence1 = new proto.waiternow.common.YearlyRecurrenceProto();
    yearlyRecurrence1.month = proto.waiternow.common.Month.NOVEMBER;
    yearlyRecurrence1.day = 2;
    yearlyRecurrence1.timeRange = timeRange;
    schedule.yearlyRecurrences.push(yearlyRecurrence1);

    const yearlyRecurrence2 = new proto.waiternow.common.YearlyRecurrenceProto();
    yearlyRecurrence2.month = proto.waiternow.common.Month.NOVEMBER;
    yearlyRecurrence2.day = 2;
    schedule.yearlyRecurrences.push(yearlyRecurrence2);

    const yearlyRecurrence3 = new proto.waiternow.common.YearlyRecurrenceProto();
    yearlyRecurrence3.month = proto.waiternow.common.Month.NOVEMBER;
    yearlyRecurrence3.timeRange = timeRange;
    schedule.yearlyRecurrences.push(yearlyRecurrence3);

    const yearlyRecurrence4 = new proto.waiternow.common.YearlyRecurrenceProto();
    yearlyRecurrence4.month = proto.waiternow.common.Month.NOVEMBER;
    schedule.yearlyRecurrences.push(yearlyRecurrence4);

    console.log(Formatter.formatSchedule(schedule, proto.waiternow.common.Language.ENGLISH));
    console.log(Formatter.formatSchedule(schedule, proto.waiternow.common.Language.SPANISH));
  */
  static formatSchedule(schedule: proto.waiternow.common.IScheduleProto | null | undefined, language: proto.waiternow.common.Language): string {
    if (!schedule) {
      return '';
    }

    const isSpanish = language == proto.waiternow.common.Language.SPANISH;

    const lines: Array<string> = [];

    const timeRangeFormatter: Function<proto.waiternow.common.ITimeRangeProto | null | undefined, string> = timeRange => {
      if (!timeRange) {
        return '';
      }
      let text = '';
      if (timeRange.from && timeRange.to) {
        text += isSpanish ? 'de ' : 'from '
        text += Formatter.formatTimeProto(timeRange.from);
        text += isSpanish ? ' a ' : ' to '
        text += Formatter.formatTimeProto(timeRange.to);
      }
      else if (timeRange.from && !timeRange.to) {
        text += isSpanish ? 'desde las ' : 'from '
        text += Formatter.formatTimeProto(timeRange.from);
      }
      else if (!timeRange.from && timeRange.to) {
        text += isSpanish ? 'hasta las ' : 'until '
        text += Formatter.formatTimeProto(timeRange.to);
      }
      return text;
    }

    for (const dateTimeRange of schedule.dateTimeRanges || []) {
      let text = isSpanish ? 'Del ' : 'From ';
      text += Formatter.formatDateTimeProto(dateTimeRange.from);
      text += isSpanish ? ' al ' : ' to ';
      text += Formatter.formatDateTimeProto(dateTimeRange.to);
      lines.push(text);
    }

    for (const dailyRecurrence of schedule.dailyRecurrences || []) {
      let text = '';
      switch(dailyRecurrence.recurrenceType) {
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.DAILY:
          text = isSpanish ? 'Diario ' : 'Daily ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKDAYS:
          text = isSpanish ? 'Entre semana ' : 'Weekdays ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKENDS:
          text = isSpanish ? 'Fin de semana ' : 'Weekends ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKLY_ON_SUNDAY:
          text = isSpanish ? 'Domingos ' : 'Sundays ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKLY_ON_MONDAY:
          text = isSpanish ? 'Lunes ' : 'Mondays ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKLY_ON_TUESDAY:
          text = isSpanish ? 'Martes ' : 'Tuesdays ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKLY_ON_WEDNESDAY:
          text = isSpanish ? 'Miércoles ' : 'Wednesdays ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKLY_ON_THURSDAY:
          text = isSpanish ? 'Jueves ' : 'Thursdays ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKLY_ON_FRIDAY:
          text = isSpanish ? 'Viernes ' : 'Fridays ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.WEEKLY_ON_SATURDAY:
          text = isSpanish ? 'Sábados ' : 'Saturdays ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_FIRST_SUNDAY:
          text = isSpanish ? 'Primer Domingo del mes ' : 'First Sunday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_FIRST_MONDAY:
          text = isSpanish ? 'Primer Lunes del mes ' : 'First Monday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_FIRST_TUESDAY:
          text = isSpanish ? 'Primer Martes del mes ' : 'First Tuesday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_FIRST_WEDNESDAY:
          text = isSpanish ? 'Primer Miércoles del mes ' : 'First Wednesday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_FIRST_THURSDAY:
          text = isSpanish ? 'Primer Jueves del mes ' : 'First Thursday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_FIRST_FRIDAY:
          text = isSpanish ? 'Primer Viernes del mes ' : 'First Friday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_FIRST_SATURDAY:
          text = isSpanish ? 'Primer Sábado del mes ' : 'First Saturday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_SECOND_SUNDAY:
          text = isSpanish ? 'Segundo Domingo del mes ' : 'Second Sunday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_SECOND_MONDAY:
          text = isSpanish ? 'Segundo Lunes del mes ' : 'Second Monday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_SECOND_TUESDAY:
          text = isSpanish ? 'Segundo Martes del mes ' : 'Second Tuesday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_SECOND_WEDNESDAY:
          text = isSpanish ? 'Segundo Miércoles del mes ' : 'Second Wednesday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_SECOND_THURSDAY:
          text = isSpanish ? 'Segundo Jueves del mes ' : 'Second Thursday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_SECOND_FRIDAY:
          text = isSpanish ? 'Segundo Viernes del mes ' : 'Second Friday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_SECOND_SATURDAY:
          text = isSpanish ? 'Segundo Sábado del mes ' : 'Second Saturday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_THIRD_SUNDAY:
          text = isSpanish ? 'Tercer Domingo del mes ' : 'Third Sunday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_THIRD_MONDAY:
          text = isSpanish ? 'Tercer Lunes del mes ' : 'Third Monday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_THIRD_TUESDAY:
          text = isSpanish ? 'Tercer Martes del mes ' : 'Third Tuesday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_THIRD_WEDNESDAY:
          text = isSpanish ? 'Tercer Miércoles del mes ' : 'Third Wednesday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_THIRD_THURSDAY:
          text = isSpanish ? 'Tercer Jueves del mes ' : 'Third Thursday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_THIRD_FRIDAY:
          text = isSpanish ? 'Tercer Viernes del mes ' : 'Third Friday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_THIRD_SATURDAY:
          text = isSpanish ? 'Tercer Sábado del mes ' : 'Third Saturday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_LAST_SUNDAY:
          text = isSpanish ? 'Último Domingo del mes ' : 'Last Sunday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_LAST_MONDAY:
          text = isSpanish ? 'Último Lunes del mes ' : 'Last Monday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_LAST_TUESDAY:
          text = isSpanish ? 'Último Martes del mes ' : 'Last Tuesday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_LAST_WEDNESDAY:
          text = isSpanish ? 'Último Miércoles del mes ' : 'Last Wednesday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_LAST_THURSDAY:
          text = isSpanish ? 'Último Jueves del mes ' : 'Last Thursday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_LAST_FRIDAY:
          text = isSpanish ? 'Último Viernes del mes ' : 'Last Friday of the month ';
          break;
        case proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.MONTHLY_ON_LAST_SATURDAY:
          text = isSpanish ? 'Último Sábado del mes ' : 'Last Saturday of the month ';
          break;
      }
      text += timeRangeFormatter(dailyRecurrence.timeRange);
      lines.push(text);
    }

    for (const monthlyRecurrence of schedule.monthlyRecurrences || []) {
      let text = isSpanish ? 'Mensual el dia ' : 'Monthly on day ';
      text += monthlyRecurrence.montlyOnDay;
      if (monthlyRecurrence.timeRange) {
        text += ' ' + timeRangeFormatter(monthlyRecurrence.timeRange);
      }
      lines.push(text);
    }

    for (const yearlyRecurrence of schedule.yearlyRecurrences || []) {
      let text = isSpanish ? 'Anual' : 'Yearly';
      if (yearlyRecurrence.day) {
        text += isSpanish ? ' en ' : ' on ';
        text += Formatter.formatMonth(yearlyRecurrence.month, language);
        text += ' ' + yearlyRecurrence.day;
      } else {
        text += isSpanish ? ' en ' : ' on ';
        text += Formatter.formatMonth(yearlyRecurrence.month, language);
      }
      if (yearlyRecurrence.timeRange) {
        text += ' ' + timeRangeFormatter(yearlyRecurrence.timeRange);
      }
      lines.push(text);
    }

    // let text = lines.join('\n');
    let text = lines.join(', ');

    if (schedule.isInverted) {
      text = 'No (' + text + ')';
    }

    return text;
  }
}
