<app-page>
    <div>
        <table>
            <tr>
                <td>
                    <app-action [text]="'expand_all' | translate" [defaultStyle]=true (onExecute)="accordion.openAll()"></app-action>
                </td>
                <td>
                    <app-action [text]="'collapse_all' | translate" [defaultStyle]=true (onExecute)="accordion.closeAll()"></app-action>
                </td>
            </tr>
        </table>
    </div>

    <mat-accordion multi>
        <!-- ------------------------------------------------------------------ -->
        <!-- Server Configuration -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'server_configuration' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-action
                [text]="'server_configuration' | translate"
                [urlToOpenInNewWindowWithFormRequest]="EnvironmentUtil.resolveBackendUrl('/service/admin/system/config/get')"
                [formParams]="[sessionService.getAuthTokenFormParam()]">
            </app-action>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Monitoring -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'monitoring' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-action [text]="'check_orders_that_require_attention' | translate" formMethod="GET"
                [urlToOpenInNewWindowWithFormRequest]="EnvironmentUtil.resolveBackendUrl('/service/monitoring/check_orders_that_require_attention')">
            </app-action>
            <br>
            <app-action [text]="'check_locations_that_should_be_open' | translate" formMethod="GET"
                [urlToOpenInNewWindowWithFormRequest]="EnvironmentUtil.resolveBackendUrl('/service/monitoring/check_locations_that_should_be_open')">
            </app-action>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Encryption -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'encryption' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form method="POST" [action]="EnvironmentUtil.resolveBackendUrl('/service/debug/encrypt')" target="_blank" #formRef>
                <input type="hidden" name="AuthToken" [value]="sessionService.getAuthToken()"/>
                <table appFieldContainer>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'text_to_encrypt' | translate }}</mat-label>
                                <input matInput name="data" [formControl]="encryption.textToEncrypt" required>
                                <mat-error *ngIf="encryption.textToEncrypt.invalid">{{formService.getFieldErrorMessage(encryption.textToEncrypt)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td align="center">
                            <app-action [text]="'submit' | translate" (onExecute)="formRef.submit()" [disabled]="encryption.textToEncrypt.invalid">
                            </app-action>
                        </td>
                    </tr>
                </table>
            </form>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Address validation -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'address_validation' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table-layout [columns]="deviceService.ifMobile(1).orElse(2)" [items]="2">
                <table #layout appFieldContainer>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'address' | translate }}</mat-label>
                                <input matInput placeholder="1600 Amphitheatre Parkway Mountain View, CA 94043" [formControl]="addressValidationFields.address" required>
                                <mat-error *ngIf="addressValidationFields.address.invalid">{{formService.getFieldErrorMessage(addressValidationFields.address)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td align="center">
                            <app-action [text]="'submit' | translate"
                                    (onAsyncExecute)="validateAddress($event)"
                                    [disabled]="addressValidationFields.address.invalid">
                            </app-action>
                        </td>
                    </tr>
                </table>
                <div #layout>
                    {{ 'response' | translate}}:
                    <p>
                    <mat-form-field appField>
                        <textarea matInput readonly>{{ addressValidationFields.response }}</textarea>
                    </mat-form-field>
                </div>
            </app-table-layout>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Geo Distance -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'geo_distance' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table-layout [columns]="deviceService.ifMobile(1).orElse(2)" [items]="2">
                <table #layout appFieldContainer>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'from_address' | translate }}</mat-label>
                                <input matInput placeholder="1600 Amphitheatre Parkway Mountain View, CA 94043" [formControl]="geoDistanceFields.fromAddress" required>
                                <mat-error *ngIf="geoDistanceFields.fromAddress.invalid">{{formService.getFieldErrorMessage(geoDistanceFields.fromAddress)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'to_address' | translate }}</mat-label>
                                <input matInput placeholder="1600 Amphitheatre Parkway Mountain View, CA 94043" [formControl]="geoDistanceFields.toAddress" required>
                                <mat-error *ngIf="geoDistanceFields.toAddress.invalid">{{formService.getFieldErrorMessage(geoDistanceFields.toAddress)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td align="center">
                            <app-action [text]="'submit' | translate"
                                    (onAsyncExecute)="calculateGeoDistance($event)"
                                    [disabled]="geoDistanceFields.fromAddress.invalid || geoDistanceFields.toAddress.invalid">
                            </app-action>
                        </td>
                    </tr>
                </table>
                <div #layout>
                    {{ 'response' | translate}}:
                    <p>
                    <mat-form-field appField>
                        <textarea matInput readonly>{{ geoDistanceFields.response }}</textarea>
                    </mat-form-field>
                </div>
            </app-table-layout>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Delivery quote -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'delivery_quote' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table-layout [columns]="deviceService.ifMobile(1).orElse(2)" [items]="2">
                <table #layout appFieldContainer>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'location_id' | translate }}</mat-label>
                                <input matInput [formControl]="deliveryQuoteFields.locationId" required>
                                <mat-error *ngIf="deliveryQuoteFields.locationId.invalid">{{formService.getFieldErrorMessage(deliveryQuoteFields.locationId)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'address' | translate }}</mat-label>
                                <input matInput placeholder="1600 Amphitheatre Parkway Mountain View, CA 94043" [formControl]="deliveryQuoteFields.address" required>
                                <mat-error *ngIf="deliveryQuoteFields.address.invalid">{{formService.getFieldErrorMessage(deliveryQuoteFields.address)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'email' | translate }}</mat-label>
                                <input matInput placeholder="my-email@email.com" [formControl]="deliveryQuoteFields.email" required>
                                <mat-error *ngIf="deliveryQuoteFields.email.invalid">{{formService.getFieldErrorMessage(deliveryQuoteFields.email)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'phone_number' | translate }}</mat-label>
                                <input matInput [formControl]="deliveryQuoteFields.phoneNumber" required>
                                <mat-error *ngIf="deliveryQuoteFields.phoneNumber.invalid">{{formService.getFieldErrorMessage(deliveryQuoteFields.phoneNumber)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div appFieldContainer>
                                <app-money-picker
                                    label="{{ 'delivery_tip' | translate }}"
                                    [required]="false"
                                    [currencies]="CURRENCIES"
                                    (onValueChange)="onDeliveryTipChange($event)">
                                </app-money-picker>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td align="center">
                            <app-action [text]="'submit' | translate"
                                    (onAsyncExecute)="getDeliveryQuote($event)"
                                    [disabled]="deliveryQuoteFields.locationId.invalid || deliveryQuoteFields.address.invalid || deliveryQuoteFields.email.invalid || deliveryQuoteFields.phoneNumber.invalid">
                            </app-action>
                        </td>
                    </tr>
                </table>
                <div #layout>
                    {{ 'response' | translate}}:
                    <p>
                    <mat-form-field appField>
                        <textarea matInput readonly>{{ deliveryQuoteFields.response }}</textarea>
                    </mat-form-field>
                </div>
            </app-table-layout>
        </mat-expansion-panel>
    </mat-accordion>
</app-page>
