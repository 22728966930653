import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from '../../components/page/page.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DeviceService } from '../../../general/services/device.service';
import { FormService } from '../../../general/services/form.service';
import { EditLocationSettingsComponent } from '../../components/edit-location-settings/edit-location-settings.component';
import { SessionService } from '../../services/session.service';
import { BackendService } from '../../services/backend.service';
import { Consumer, Runnable } from 'src/app/general/interfaces/functions';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { ProtoUtil } from '../../util/proto-util';
import { Formatter } from '../../util/formatter';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { AuthenticationStatus } from '../../util/util';
import { AddressPickerComponent, Address } from 'src/app/general/components/address-picker/address-picker.component';
import { COUNTRIES_NAMES } from '../../util/constants';
import { AppError } from 'src/app/general/util/error';
import { Util } from 'src/app/general/util/util';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ErrorResult, LoadingMessage, SuccessResult } from "src/app/general/util/result";
import { NavigationService } from '../../services/navigation.service';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-edit-location-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    EditLocationSettingsComponent,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    AddressPickerComponent,
    ActionComponent,
    LoadableContentComponent
  ],
  templateUrl: './edit-location-page.component.html',
  styleUrls: ['./edit-location-page.component.css']
})
export class EditLocationPageComponent implements OnInit {
  readonly COUNTRIES_NAMES: Array<string> = COUNTRIES_NAMES;

  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild(EditLocationSettingsComponent) editLocationSettingsComponent!: EditLocationSettingsComponent;

  locationId: string;
  locationSettings: proto.waiternow.common.LocationProto.ISettingsProto | null | undefined;
  pageTitle: string;
  locationLoadingController: LoadingController;

  generalConfigFields = new (class {
    name: FormControl = new FormControl('', [Validators.required]);
    phoneNumber: FormControl = new FormControl('', [Validators.required]);
    addressInitialValue: Address | null | undefined;
    addressCurrentValue: Address | null | undefined;
  })();

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.locationId = '';

    this.pageTitle = '';

    this.locationLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.locationId = Util.safeString(routeParams.get('locationId'));
    this.loadLocation();
  }

  public editLocationSettingsComponentAccordionOpenAll(): void {
    this.editLocationSettingsComponent.accordionOpenAll();
  }

  public goToLocationPage(): void {
    this.navigationService.goToLocationPage(this.locationId)
  }

  public editLocationSettingsComponentAccordionCloseAll(): void {
    this.editLocationSettingsComponent.accordionCloseAll();
  }

  public loadLocation(): void {
    this.locationLoadingController.onLoadingStarted(LoadingMessage.empty());
    this.backendService.getLocation(
      this.locationId,
      /* onSuccess= */ location => {
        this.initFormControls(location);
        this.locationLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.locationLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_location'));
      }
    );
  }

  private initFormControls(location: proto.waiternow.common.ILocationProto | null | undefined): void {
    if (!location) {
      return;
    }

    this.pageTitle = Util.safeString(location.redundantData?.businessName) + ' ' + Util.safeString(location.name);

    this.locationSettings = location?.settings;

    // General
    this.generalConfigFields.name.setValue(location?.name);
    this.generalConfigFields.phoneNumber.setValue(Formatter.formatPhoneNumber(location?.phoneNumber));
    if (location.address) {
      this.generalConfigFields.addressInitialValue = ProtoUtil.addressProtoToPickerAddress(location.address);
      this.generalConfigFields.addressCurrentValue = this.generalConfigFields.addressInitialValue;
    }
  }

  private createLocation(): proto.waiternow.common.LocationProto {
    const location = new proto.waiternow.common.LocationProto();
    location.id = this.locationId;
    return location;
  }

  private saveLocation(
      location: proto.waiternow.common.LocationProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.RemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    this.backendService.updateLocation(
      location,
      fieldsToRemove,
      /* settingsFieldsToRemove= */ [],
      /* onSuccess= */ () => onSuccess(),
      /* onError= */ error => onError(error)
    );
  }

  public saveGeneral(actionObserver: ActionObserver): void {
    const location = this.createLocation();
    const fieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.RemovableField> = [];

    location.name = this.generalConfigFields.name.value;
    if (this.generalConfigFields.phoneNumber.value) {
      location.phoneNumber = ProtoUtil.createSimplePhoneNumberProto(this.generalConfigFields.phoneNumber.value);
    } else {
      // Phone number cannot be removed
      // fieldsToRemove.push(proto.waiternow.common.UpdateLocationActionProto.Request.RemovableField.PHONE_NUMBER);
    }
    if (this.generalConfigFields.addressCurrentValue) {
      location.address = ProtoUtil.pickerAddressToAddressProto(this.generalConfigFields.addressCurrentValue);
    } else {
      // Address cannot be removed
      // fieldsToRemove.push(proto.waiternow.common.UpdateLocationActionProto.Request.RemovableField.ADDRESS);
    }

    this.saveLocation(
      location,
      fieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_location'));
      }
    );
  }

  public onLocationAddressChange(address: Address | null | undefined) {
    this.generalConfigFields.addressCurrentValue = address;
  }
}
