<app-page>
    <app-loadable-content [loadingController]="locationLoadingController" (onRetry)="loadLocation()">
        <app-title>{{ pageTitle }}</app-title>
        <div>
            <table>
                <tr>
                    <td>
                        <app-action [text]="'expand_all' | translate" [defaultStyle]=true (onExecute)="accordion.openAll(); editLocationSettingsComponentAccordionOpenAll()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'collapse_all' | translate" [defaultStyle]=true (onExecute)="accordion.closeAll(); editLocationSettingsComponentAccordionCloseAll()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'go_to_location' | translate" [defaultStyle]=true (onExecute)="goToLocationPage()"></app-action>
                    </td>
                </tr>
            </table>
        </div>
        <mat-accordion multi>
            <!-- ------------------------------------------------------------------ -->
            <!-- General config -->
            <!-- ------------------------------------------------------------------ -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'general' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div appFieldContainer>
                    <mat-form-field appField>
                        <mat-label>{{ 'name' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.name" required>
                        <mat-error *ngIf="generalConfigFields.name.invalid">{{formService.getFieldErrorMessage(generalConfigFields.name)}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appField>
                        <mat-label>{{ 'phone_number' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.phoneNumber" required>
                        <mat-error *ngIf="generalConfigFields.phoneNumber.invalid">{{formService.getFieldErrorMessage(generalConfigFields.phoneNumber)}}</mat-error>
                    </mat-form-field>
                    <div appFieldContainer>
                        <app-address-picker
                                [label]="'address' | translate"
                                [required]="true"
                                [disabled]="false"
                                [countries]="COUNTRIES_NAMES"
                                [value]="generalConfigFields.addressInitialValue"
                                (onValueChange)="onLocationAddressChange($event)">
                        </app-address-picker>
                    </div>
                </div>
                <div>
                    <app-action [text]="'save' | translate"
                        [disabled]="generalConfigFields.name.invalid || generalConfigFields.phoneNumber.invalid"
                        (onAsyncExecute)="saveGeneral($event)">
                    </app-action>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- app-edit-location-settings also defines an accordion -->
        <app-edit-location-settings
            [locationId]="locationId"
            [locationSettings]="locationSettings">
        </app-edit-location-settings>
    </app-loadable-content>
</app-page>
